import React, { useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./create-update-custom-reports.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import {
  Table,
  Tooltip,
  DatePicker,
  Button,
  Tabs,
  Input,
  Modal,
  ConfigProvider,
  Switch,
  Alert,
  Select,
  Breadcrumb,
} from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

import {
  LeftOutlined,
  PlusCircleFilled,
  PlusOutlined,
  SearchOutlined,
  SettingFilled,
  SmileOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CreateCustomColumn from "./create-custom-column";
import DateRangeFilter from "components/customReports/DateRangeFilter";

const SortableColumnTitle = ({ children, id }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "300px",
    // height: "100%",
    opacity: isDragging ? 0.8 : 1,
    backgroundColor: isDragging ? "#f0f0f0" : "transparent",
    border: isDragging ? "1px dashed #ccc" : "none",
    whiteSpace: "wrap",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <span
        style={{
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap",
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default function CreateUpdateCustomReports() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [unusedColumnsInViews, setUnusedColumnsInViews] = useState([]);
  const [unavailableReportNames, setUnavailableReportNames] = useState([]);

  const [searchParams] = useSearchParams();

  // const newTabIndex = useRef(0);
  const navigate = useNavigate();

  let reportPageTitle = reportName;
  const reportOwner = searchParams.get("owner");
  let reportTabName = reportName ? searchParams.get("tab") : null;

  const [data, setData] = useState([]);

  const [reportDescription, setReportDescription] = useState("");
  const [alignedData, setAlignedData] = useState({});
  const [editedColumn, setEditedColumn] = useState();
  const [editedColumnKey, setEditedColumnKey] = useState();
  const [reportTabs, setReportTabs] = useState([]);
  const [newReportTabs, setNewReportTabs] = useState([]);
  const [deletedTabs, setdeletedTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(reportTabName);
  const [searchParam, setSearchParam] = useSearchParams();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [columnInfoData, setColumnInfoData] = useState([]);
  const [newReportName, setNewReportName] = useState("");
  const [newReportDescription, setNewReportDescription] = useState("");

  const [tabsData, setTabsData] = useState({});
  const [unsavedTabs, setUnsavedTabs] = useState({});
  const [columns, setColumns] = useState([]);

  const [groupedColumns, setGroupedColumns] = useState([]);

  // FOR CUSTOM COLUMN
  const [showNote, setShowNote] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState(null);
  const [formulaRows, setFormulaRows] = useState([
    { columnKey: "", operator: "+", type: null },
  ]);

  const presetAlignedData = {
    specTitle: {
      label: "Submittal Title",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "1",
    },
    specNumber: {
      label: "Submittal Number",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "2",
    },
    specRevision: {
      label: "Submittal Revision",
      type: "int",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "3",
    },
  };

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName ? reportName : "",
      reportTabName: reportTabName ? reportTabName : "",
    };
    ReportServices.getDataToCreateUpdateCustomReport(ReqObj)
      .then((res) => {
        const combinedAlignedData =
          Object.keys(res?.reportsAlignedData).length > 0
            ? { ...res?.reportsAlignedData }
            : { ...presetAlignedData };

        Object.entries(res?.fullReportsAlignedData || {}).forEach(
          ([key, value]) => {
            if (!combinedAlignedData[key]) {
              combinedAlignedData[key] = {
                ...value,
                index: (Object.keys(combinedAlignedData).length + 1).toString(),
                // usedStatus:  "",
                usedStatus: res?.groupedColumns.includes(key) ? "used" : "",
                grouping: res?.groupedColumns.includes(key) ? "Yes" : "",
              };
            }
          }
        );

        if (reportTabName) {
          setTabsData((prev) => ({
            ...prev,
            [reportTabName]: {
              reportInfo: {
                reportDescription: res?.reportInfo?.reportDescription,
                reportTabs: res?.reportInfo?.reportTabs,
              },
              oldTabName: reportTabName,
              newTabName: reportTabName,
              procurementData: res?.procurementLogEntriesCleaned,
              alignedData: combinedAlignedData,
              accessLevel: parseInt(res?.userData?.accessLevel),
              groupedColumns: res?.groupedColumns || [],
            },
          }));

          if (Object.keys(res?.reportsAlignedData).length < 1) {
            setUnsavedTabs((prev) => ({
              ...prev,
              [reportTabName]: true,
            }));
          }
        } else {
          // create new tab for first time access
          setNewReportTabs([...newReportTabs, "Untitled"]);
          onTabChange("Untitled");

          setTabsData((prev) => ({
            ...prev,
            [reportTabName]: {
              reportInfo: {
                reportDescription: res?.reportInfo?.reportDescription,
                reportTabs: res?.reportInfo?.reportTabs,
              },
              oldTabName: reportTabName,
              newTabName: reportTabName,
              procurementData: res?.procurementLogEntriesCleaned,
              alignedData: combinedAlignedData,
              accessLevel: parseInt(res?.userData?.accessLevel),
              groupedColumns: res?.groupedColumns || [],
            },
          }));
          setUnsavedTabs((prev) => ({
            ...prev,
            [reportTabName]: true,
          }));
        }

        setReportDescription(res?.reportInfo?.reportDescription);
        setReportTabs(res?.reportInfo?.reportTabs);
        setData(res?.procurementLogEntriesCleaned);
        setAccessLevel(parseInt(res?.userData?.accessLevel));
        setUnusedColumnsInViews(res?.unusedColumnsInViews);
        setUnavailableReportNames(res?.usedReportNames);
        setAlignedData(combinedAlignedData);
        setGroupedColumns(res?.groupedColumns);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }
  useEffect(() => {
    const updatedFilteredInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.filtering) {
          acc[key] = value.filtering.split(",").map((item) => item.trim());
        }
        return acc;
      },
      {}
    );

    setFilteredInfo(updatedFilteredInfo);

    const data = Object.entries(alignedData || {})
      .filter(([key, value]) => value.label)
      .sort(([, a], [, b]) => (a?.label || "")?.localeCompare(b?.label || ""))
      .filter(
        ([key]) =>
          !unusedColumnsInViews.includes(key) &&
          key !== "entryID" &&
          key !== "group"
        // key !== "specSection"
      );

    setColumnInfoData(data);
  }, [alignedData]);

  useEffect(() => {
    getData();
  }, []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const parseSubmittalNumber = (number) => {
    return number
      .split(/[^0-9]+/)
      .filter(Boolean) // remove "-"
      .map(Number);
  };

  useEffect(() => {
    let newColumns = [];

    if (alignedData?.group) {
      newColumns.push({
        title: "Group",
        dataIndex: "group",
        key: "group",
        align: "left",
        width: 200,
        fixed: "left",
        render: (text) => {
          return <span style={{ fontWeight: "bold" }}>{text}</span>;
        },
      });
    }

    newColumns.push(
      {
        title: "Submittal Title",
        dataIndex: "specTitle",
        key: "specTitle",
        align: "left",
        width: 400,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specTitle?.sorting === "Descending"
            ? "descend"
            : alignedData?.specTitle?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: showNote
          ? false
          : (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row.specTitle;
              };

              if (groupedColumns.length === 0 && !alignedData?.group) {
                return a.specTitle.localeCompare(b.specTitle);
              } else {
                if (a.group || b.group) return 0;

                // Only sort if both rows are under the same parent group
                if (a.parentGroup === b.parentGroup) {
                  const aEmpty = isEmptyRow(a);
                  const bEmpty = isEmptyRow(b);

                  // If both empty or both non-empty, sort normally
                  if (aEmpty === bEmpty) {
                    if (a.specTitle && b.specTitle) {
                      return a.specTitle.localeCompare(b.specTitle);
                    }
                    return 0;
                  }
                  if (aEmpty) return -1;
                  if (bEmpty) return 1;
                }
                return 0;
              }
            },
      },
      {
        title: "Submittal Number",
        dataIndex: "specNumber",
        key: "specNumber",
        align: "center",
        width: 150,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specNumber?.sorting === "Descending"
            ? "descend"
            : alignedData?.specNumber?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: showNote
          ? false
          : (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row.specNumber;
              };
              if (groupedColumns.length === 0 && !alignedData?.group) {
                return a.specNumber.localeCompare(b.specNumber);
              }
              if (a.group || b.group) return 0;

              // Only sort if both rows are under the same parent group
              if (a.parentGroup === b.parentGroup) {
                const aEmpty = isEmptyRow(a);
                const bEmpty = isEmptyRow(b);

                // If both empty or both non-empty, sort normally
                if (aEmpty === bEmpty) {
                  if (a.specNumber && b.specNumber) {
                    return parseSubmittalNumber(a.specNumber)
                      .toString()
                      .localeCompare(
                        parseSubmittalNumber(b.specNumber).toString(),
                        undefined,
                        { numeric: true }
                      );
                  }
                  return 0;
                }
                // Empty rows are placed to the bottom or top of the group
                if (aEmpty) return -1;
                if (bEmpty) return 1;
              }
              return 0;
            },
        // sorter:
        //   groupedColumns.length === 0 && !alignedData?.group
        //     ? (a, b) => a.specNumber?.localeCompare(b.specNumber)
        //     : false,
      },
      {
        title: "Submittal Revision",
        dataIndex: "specRevision",
        key: "specRevision",
        align: "center",
        width: 150,
        // fixed: "left",
        defaultSortOrder:
          alignedData?.specRevision?.sorting === "Descending"
            ? "descend"
            : alignedData?.specRevision?.sorting === "Ascending"
            ? "ascend"
            : "",
        sorter: showNote
          ? false
          : (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row.specRevision;
              };
              if (groupedColumns.length === 0 && !alignedData?.group) {
                return Number(a.specRevision) - Number(b.specRevision);
              }
              if (a.group || b.group) return 0;

              // Only sort if both rows are under the same parent group
              if (a.parentGroup === b.parentGroup) {
                const aEmpty = isEmptyRow(a);
                const bEmpty = isEmptyRow(b);

                // If both empty or both non-empty, sort normally
                if (aEmpty === bEmpty) {
                  if (a.specRevision && b.specRevision) {
                    return Number(a.specRevision) - Number(b.specRevision);
                  }
                  return 0;
                }
                // Empty rows are placed to the bottom or top of the group
                if (aEmpty) return -1;
                if (bEmpty) return 1;
              }
              return 0;
            },
        // sorter:
        //   groupedColumns.length === 0 && !alignedData?.group
        //     ? (a, b) => Number(a.specRevision) - Number(b.specRevision)
        //     : false,
      }
    );

    newColumns = [
      ...newColumns,
      ...Object.entries(alignedData)
        .filter(
          ([key, value]) =>
            ![
              "specTitle",
              "specNumber",
              "specRevision",
              "group",
              // "specSection",
            ].includes(key) &&
            value.usedStatus === "used" &&
            !groupedColumns.includes(key)
        )
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
          const uniqueValues = [
            ...new Set(dataSource.map((item) => item[key])),
          ];
          const columnConfig = {
            title: value.label || key,
            dataIndex: key,
            key: key,
            align: "center",
            filterMode: "tree",
            filterSearch: true,
            filteredValue: filteredInfo[key] || null,
            defaultSortOrder:
              tabsData[selectedTab]?.alignedData?.[key]?.sorting ===
              "Descending"
                ? "descend"
                : tabsData[selectedTab]?.alignedData?.[key]?.sorting ===
                  "Ascending"
                ? "ascend"
                : "",
            width: 200,
          };

          if (value.type === "int") {
            columnConfig.filters = showNote
              ? false
              : uniqueValues
                  .filter((v) => v !== undefined && v !== null)
                  .map((uniqueValue) => ({
                    text: uniqueValue !== "" ? uniqueValue : "[Blank]",
                    value: uniqueValue !== "" ? uniqueValue : "",
                  }));

            if (groupedColumns.length === 0 && !alignedData?.group) {
              columnConfig.onFilter = (filterValue, record) => {
                const cellValue = record[key];
                if (filterValue === "") {
                  return cellValue === "";
                }
                return String(cellValue).startsWith(filterValue);
              };
              columnConfig.sorter = (a, b) => {
                const numA = Number(a[key]);
                const numB = Number(b[key]);
                return numA - numB;
              };
            } else {
              columnConfig.onFilter = showNote
                ? false
                : (filterValue, record) => {
                    const cellValue = record[key];

                    if (filterValue === "") {
                      return cellValue === "";
                    }
                    if (record.group) {
                      return true;
                    }

                    return String(cellValue).startsWith(filterValue);
                  };

              columnConfig.sorter = showNote
                ? false
                : (a, b) => {
                    const isEmptyRow = (row) => {
                      return !row.group && !row[key];
                    };
                    if (a.group || b.group) return 0;

                    // Only sort if both rows are under the same parent group
                    if (a.parentGroup === b.parentGroup) {
                      const aEmpty = isEmptyRow(a);
                      const bEmpty = isEmptyRow(b);

                      // If both empty or both non-empty, sort normally
                      if (aEmpty === bEmpty) {
                        if (a[key] && b[key]) {
                          return Number(a[key]) - Number(b[key]);
                        }
                        return 0;
                      }
                      // Empty rows always go to bottom
                      if (aEmpty) return 1;
                      if (bEmpty) return -1;
                    }
                    return 0;
                  };
            }
          } else if (value.type === "string") {
            columnConfig.filters = showNote
              ? false
              : uniqueValues
                  .filter((v) => v !== undefined && v !== null)
                  .map((uniqueValue) => ({
                    text: uniqueValue !== "" ? uniqueValue : "[Blank]",
                    value: uniqueValue !== "" ? uniqueValue : "",
                  }));

            if (groupedColumns.length === 0 && !alignedData?.group) {
              columnConfig.onFilter = (filterValue, record) => {
                const cellValue = record[key];
                if (filterValue === "") {
                  return cellValue === "";
                }
                return String(cellValue).startsWith(filterValue);
              };

              columnConfig.sorter = (a, b) => {
                const strA = String(a[key]);
                const strB = String(b[key]);
                return strA.localeCompare(strB);
              };
            } else {
              columnConfig.onFilter = showNote
                ? false
                : (filterValue, record) => {
                    const cellValue = record[key];

                    if (filterValue === "") {
                      return cellValue === "";
                    }
                    if (record.group) {
                      return true;
                    }

                    return String(cellValue).startsWith(filterValue);
                  };

              columnConfig.sorter = showNote
                ? false
                : (a, b) => {
                    const isEmptyRow = (row) => {
                      return !row.group && !row[key];
                    };
                    if (a.group || b.group) return 0;

                    // Only sort if both rows are under the same parent group
                    if (a.parentGroup === b.parentGroup) {
                      const aEmpty = isEmptyRow(a);
                      const bEmpty = isEmptyRow(b);

                      // If both empty or both non-empty, sort normally
                      if (aEmpty === bEmpty) {
                        if (a[key] && b[key]) {
                          const strA = String(a[key]);
                          const strB = String(b[key]);
                          return strA.localeCompare(strB);
                        }
                        return 0;
                      }
                      // Empty rows always go to bottom
                      if (aEmpty) return 1;
                      if (bEmpty) return -1;
                    }
                    return 0;
                  };
            }
          } else if (value.type === "date") {
            columnConfig.filterDropdown = showNote
              ? false
              : (props) => (
                  <DateRangeFilter
                    {...props}
                    columnKey={key}
                    setFilteredInfo={setFilteredInfo}
                    setTabsData={setTabsData}
                    selectedTab={selectedTab}
                    reportTabName={reportTabName}
                    setUnsavedTabs={setUnsavedTabs}
                    EditMode={true}
                  />
                );

            if (groupedColumns.length === 0 && !alignedData?.group) {
              columnConfig.sorter = (a, b) => {
                const dateA = a[key] ? new Date(a[key]) : null;
                const dateB = b[key] ? new Date(b[key]) : null;

                // Handle cases with null or invalid dates
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;

                // Compare dates
                return dateA.getTime() - dateB.getTime();
              };

              columnConfig.onFilter = (value, record) => {
                const recordDate = moment(record[key], "MM-DD-YYYY");

                if (!recordDate.isValid()) {
                  // console.error("Invalid record date:", record[key]);
                  return false;
                }

                let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
                let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

                // Convert startDate and endDate to moment objects
                const formattedStartDate = startDate
                  ? moment(startDate, "MM-DD-YYYY")
                  : null;
                const formattedEndDate = endDate
                  ? moment(endDate, "MM-DD-YYYY")
                  : null;

                // Check if the formattedStartDate and formattedEndDate are valid
                if (formattedStartDate && !formattedStartDate.isValid()) {
                  console.error("Invalid start date:", startDate);
                  return false;
                }

                if (formattedEndDate && !formattedEndDate.isValid()) {
                  console.error("Invalid end date:", endDate);
                  return false;
                }

                const isDateInRange = recordDate.isBetween(
                  formattedStartDate,
                  formattedEndDate,
                  null,
                  "[]"
                );

                // Return true if the date is within the range, false otherwise
                return isDateInRange;
              };

              columnConfig.sorter = (a, b) => {
                // Handle cases where the date might be null or undefined
                const dateA = a[key] ? dayjs(a[key]) : null;
                const dateB = b[key] ? dayjs(b[key]) : null;

                if (dateA && dateB) {
                  return dateA.diff(dateB);
                }

                if (!dateA) return 1;
                if (!dateB) return -1;

                return 0;
              };

              columnConfig.render = (text) =>
                text ? dayjs(text).format("MM-DD-YYYY") : "";
            } else {
              columnConfig.onFilter = showNote
                ? false
                : (value, record) => {
                    const recordDate = moment(record[key], "MM-DD-YYYY");

                    if (record.group) {
                      return true;
                    }

                    if (!recordDate.isValid()) {
                      return false;
                    }

                    let startDate = filteredInfo[key]
                      ? filteredInfo[key][0]
                      : null;
                    let endDate = filteredInfo[key]
                      ? filteredInfo[key][1]
                      : null;

                    const formattedStartDate = startDate
                      ? moment(startDate, "MM-DD-YYYY")
                      : null;
                    const formattedEndDate = endDate
                      ? moment(endDate, "MM-DD-YYYY")
                      : null;

                    if (formattedStartDate && !formattedStartDate.isValid()) {
                      console.error("Invalid start date:", startDate);
                      return false;
                    }

                    if (formattedEndDate && !formattedEndDate.isValid()) {
                      console.error("Invalid end date:", endDate);
                      return false;
                    }

                    // Check if the record date is within the range
                    const isDateInRange = recordDate.isBetween(
                      formattedStartDate,
                      formattedEndDate,
                      null,
                      "[]"
                    );

                    return isDateInRange;
                  };

              columnConfig.sorter = showNote
                ? false
                : (a, b) => {
                    const isEmptyRow = (row) => {
                      return !row.group && !row[key];
                    };
                    if (a.group || b.group) return 0;

                    // Only sort if both rows are under the same parent group
                    if (a.parentGroup === b.parentGroup) {
                      const aEmpty = isEmptyRow(a);
                      const bEmpty = isEmptyRow(b);

                      // If both empty or both non-empty, sort normally
                      if (aEmpty === bEmpty) {
                        if (a[key] && b[key]) {
                          const dateA = a[key] ? dayjs(a[key]) : null;
                          const dateB = b[key] ? dayjs(b[key]) : null;
                          return dateA.diff(dateB);
                        }
                        return 0;
                      }
                      // Empty rows always go to bottom
                      if (aEmpty) return 1;
                      if (bEmpty) return -1;
                    }
                    return 0;
                  };
            }
          }

          return columnConfig;
        }),
    ];

    setColumns(newColumns);
  }, [alignedData, filteredInfo, dataSource]);

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item, index) => ({
        key: item?.entryID || `entryID-${index}`,
        parentGroup: `${item?.parentGroup}`,
        specTitle: `${item?.specTitle}`,
        specNumber: `${item?.specNumber}`,
        specRevision: `${item?.specRevision}`,
        ...Object.keys(alignedData).reduce((acc, column) => {
          acc[column] = item[column];
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, alignedData]);

  const onTabChange = (key) => {
    // Update search params and selected tab
    setSearchParam((prevParams) => {
      prevParams.set("tab", key);
      return prevParams;
    });
    setSelectedTab(key);
    reportTabName = key;

    // Check if tab data is already loaded
    if (!tabsData[key]) {
      getData(key);
    } else {
      // Use locally stored data
      const tabData = tabsData[key];
      setReportDescription(tabData?.reportInfo?.reportDescription);
      setReportTabs(tabData?.reportInfo?.reportTabs);
      setData(tabData?.procurementData);
      setAlignedData(tabData?.alignedData);
      setGroupedColumns(tabData?.groupedColumns);
    }
  };

  const saveReport = async () => {
    // console.log(tabsData, "tabsDataaa");

    const tabsToSave = Object.keys(unsavedTabs).filter(
      (tab) =>
        !deletedTabs.includes(tab) &&
        (reportTabs?.includes(tab) || newReportTabs?.includes(tab))
    );

    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    // console.log(tabsToSave, "Tabs to save:");

    // Save tabs
    const savePromises = tabsToSave.map((tabName) => {
      const tabData = tabsData[tabName];

      // Determine whether to create or update based on newReportTabs
      const isCreateMode = newReportTabs.includes(tabName) || !reportName;

      const saveRequest = isCreateMode
        ? {
            company: company_id,
            project: id,
            code,
            loginEmail: email,
            reportName: reportName || newReportName,
            reportTabName: tabData?.newTabName || tabName,
            reportDescription: newReportDescription || reportDescription || "",
            columnsInfo: tabData?.alignedData,
          }
        : {
            company: company_id,
            project: id,
            code,
            loginEmail: email,
            reportName: reportName,
            newReportName: newReportName || reportName,
            reportTabName: tabData?.oldTabName || tabName,
            newReportTabName: tabData?.newTabName || tabName,
            reportDescription: newReportDescription || reportDescription,
            columnsInfo: tabData?.alignedData,
          };

      console.log(
        `Sending ${
          isCreateMode ? "create" : "update"
        } request for tab: ${tabName}`,
        saveRequest
      );

      return isCreateMode
        ? ReportServices.createCustomReport(saveRequest)
        : ReportServices.updateCustomReport(saveRequest);
    });

    // Delete tabs
    let deletePromise = Promise.resolve();
    if (deletedTabs.length > 0) {
      const deleteRequest = {
        company: company_id,
        project: id,
        code,
        loginEmail: email,
        reportName: reportName,
        reportTabNames: deletedTabs,
      };

      deletePromise = ReportServices.deleteCustomReport(deleteRequest);
    }

    try {
      await Promise.all([...savePromises, deletePromise]);

      // const basePath = window.location.pathname
      //   .split("/")
      //   .slice(0, reportTabs ? -2 : -1)
      //   .join("/");

      // window.location.href = `${basePath}/`;

      // Redirect to the view report page
      // console.log(tabsData, "!?!?");

      const reportToRedirect = reportName || newReportName;

      let firstTab = tabsToSave.find(
        (tab) =>
          !deletedTabs.includes(tab) &&
          (reportTabs?.includes(tab) || newReportTabs?.includes(tab))
      );

      if (!firstTab) {
        // console.log(reportTabs, "!!!");
        firstTab = reportTabs[0];
      }

      const firstTabName =
        firstTab && tabsData[firstTab]?.newTabName
          ? tabsData[firstTab].newTabName
          : reportTabs[0];

      // console.log(firstTab, "!!");

      if (reportToRedirect && email) {
        const viewReportUrl = `/main/company/${company_id}/projects/${id}/project-custom-reports/${encodeURIComponent(
          reportToRedirect
        )}?owner=${encodeURIComponent(email)}&tab=${encodeURIComponent(
          firstTabName || ""
        )}`;
        window.location.href = viewReportUrl;
      }
    } catch (error) {
      console.error("Failed to save or delete tabs:", error);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    const currentTabKey = selectedTab || reportTabName;

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      updatedTabsData[currentTabKey] = {
        ...updatedTabsData[currentTabKey],
        alignedData: {
          ...Object.fromEntries(
            Object.entries(updatedTabsData[currentTabKey].alignedData).map(
              ([key, value]) => [
                key,
                {
                  ...value,
                  sorting: "", // Reset sorting for other columns
                  filtering: filters[key] ? filters[key].join(",") : "", // Apply filtering
                },
              ]
            )
          ),
          // Apply sorting for the current column
          ...(sorter && sorter.column && "order" in sorter
            ? {
                [sorter.column.dataIndex]: {
                  ...updatedTabsData[currentTabKey].alignedData[
                    sorter.column.dataIndex
                  ],
                  sorting:
                    sorter.order === "ascend"
                      ? "Ascending"
                      : sorter.order === "descend"
                      ? "Descending"
                      : "",
                },
              }
            : {}),
        },
      };

      return updatedTabsData;
    });

    // Save the filter information
    setFilteredInfo(filters);

    // Mark the current tab as unsaved
    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));
  };

  const handleGroupingChange = (selection) => {
    // add limit max 4 items per grouping
    if (selection.length > 4) {
      return;
    }
    const currentTabKey = selectedTab || reportTabName;

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };
      updatedTabsData[currentTabKey] = {
        ...updatedTabsData[currentTabKey],
        groupedColumns: selection,
        alignedData: Object.fromEntries(
          Object.entries(updatedTabsData[currentTabKey].alignedData).map(
            ([key, value]) => [
              key,
              {
                ...value,
                usedStatus: selection.includes(key)
                  ? value.usedStatus !== "used"
                    ? "used"
                    : value.usedStatus
                  : value.usedStatus,
                grouping: selection.includes(key) ? "Yes" : "",
                sorting: "",
                filtering: "",
              },
            ]
          )
        ),
      };

      return updatedTabsData;
    });

    setGroupedColumns(selection);
    setFilteredInfo({});

    setShowNote(true);

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));
  };

  useEffect(() => {
    console.log(tabsData, "Tabs");
  }, [tabsData]);

  // Search item names or submittal number
  const [searchQuery, setSearchQuery] = useState("");

  const isFilteredInfoEmpty = (filteredInfo) => {
    if (!filteredInfo || typeof filteredInfo !== "object") return true;
    const values = Object.values(filteredInfo);
    return values.length === 0 || values.every((value) => value == null);
  };

  const [isNewTabModalVisible, setIsNewTabModalVisible] = useState(false);
  const [isCustomColumnModalVisible, setIsCustomColumnModalVisible] =
    useState(false);
  const [newTabName, setNewTabName] = useState("");
  const [renameFlag, setRenameFlag] = useState(false);
  const [newRenamedTabsArray, setNewRenamedTabsArray] = useState([]);

  const add = () => {
    setIsNewTabModalVisible(true);
  };

  const handleNewTabConfirm = () => {
    if (!renameFlag) {
      if (newTabName.trim()) {
        // const currentTabs = Array.isArray(reportTabs) ? reportTabs : [];

        // setSearchParam((prevParams) => {
        //   prevParams.set("tab", newTabName.trim());
        //   return prevParams;
        // });

        // setSelectedTab(newTabName.trim());
        setNewReportTabs([...newReportTabs, newTabName.trim()]);
        onTabChange(newTabName.trim());
      }
    } else {
      setTabsData((prevTabsData) => {
        const updatedTabsData = { ...prevTabsData };

        if (updatedTabsData[selectedTab]) {
          updatedTabsData[selectedTab] = {
            ...updatedTabsData[selectedTab],
            newTabName: newTabName.trim(),
          };
        }
        return updatedTabsData;
      });

      let renamedTabs = [...newRenamedTabsArray];
      renamedTabs.push(newTabName.trim());

      setNewRenamedTabsArray(renamedTabs);
      setRenameFlag(false);
      setUnsavedTabs((prev) => ({
        ...prev,
        [selectedTab]: true,
      }));
    }

    setIsNewTabModalVisible(false);
    setNewTabName("");
  };

  const remove = (tabName) => {
    let newDeletedTabs = [...deletedTabs];
    let updatedNewReportTabs = [...newReportTabs];

    let updatedReportTabs = Array.isArray(reportTabs) ? [...reportTabs] : [];

    if (updatedReportTabs.includes(tabName)) {
      newDeletedTabs.push(tabName);
      updatedReportTabs = reportTabs.filter((tab) => tab !== tabName);
    } else {
      updatedNewReportTabs = newReportTabs.filter((tab) => tab !== tabName);
    }

    setdeletedTabs(newDeletedTabs);
    setReportTabs(updatedReportTabs);
    setNewReportTabs(updatedNewReportTabs);

    // SWITCH TO ANOTHER OPENED TAB
    let firstTab = Object.keys(unsavedTabs).find(
      (tab) =>
        !deletedTabs.includes(tab) &&
        (reportTabs?.includes(tab) || newReportTabs?.includes(tab))
    );

    if (!firstTab) {
      firstTab = reportTabs.find(
        (tab) => !deletedTabs.includes(tab) && tab !== tabName
      );

      // if (!firstTab) {
      //   console.log("No valid tabs available.", reportTabs, deletedTabs);
      // } else {
      //   console.log("Selected first valid tab:", firstTab);
      // }
    }

    if (firstTab) {
      onTabChange(firstTab);
    }
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  const handleRenameTab = (tabName) => {
    setRenameFlag(true);
    setNewTabName(tabsData[tabName]?.newTabName || tabName);
    setIsNewTabModalVisible(true);
  };

  const filteredcolumnsInfo = columnInfoData.filter(([, value]) =>
    value.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Prevent drag and drop for (specTitle, specNumber, specRevision)
    const fixedColumns = ["specTitle", "specNumber", "specRevision", "group"];
    if (fixedColumns.includes(active.id) || fixedColumns.includes(over.id)) {
      return;
    }

    if (active.id !== over.id) {
      const updatedColumns = (prevColumns) => {
        const oldIndex = prevColumns.findIndex((col) => col.key === active.id);
        const newIndex = prevColumns.findIndex((col) => col.key === over.id);

        return arrayMove(prevColumns, oldIndex, newIndex);
      };

      const newColumns = updatedColumns(columns);

      setColumns(newColumns);

      setTabsData((prev) => {
        const currentTabKey = selectedTab || reportTabName;
        const updatedTabsData = { ...prev };

        if (updatedTabsData[currentTabKey]) {
          const updatedAlignedData = {
            ...updatedTabsData[currentTabKey].alignedData,
          };

          // Reset indexes based on new column order
          newColumns.forEach((column, index) => {
            const columnKey = column.key;
            if (updatedAlignedData[columnKey]) {
              updatedAlignedData[columnKey] = {
                ...updatedAlignedData[columnKey],
                index: index.toString(),
              };
            }
          });

          updatedTabsData[currentTabKey] = {
            ...updatedTabsData[currentTabKey],
            alignedData: updatedAlignedData,
          };

          setAlignedData(updatedAlignedData);
        }

        return updatedTabsData;
      });

      // Mark the tab as having unsaved changes
      setUnsavedTabs((prev) => ({
        ...prev,
        [selectedTab || reportTabName]: true,
      }));
    }
  };

  const parseFormulaToRows = (formula) => {
    // console.log(formula);

    if (!formula) return [{ columnKey: "", operator: "+", type: null }];

    // Split the formula by operators and keep the delimiters (operators)
    const tokens = formula.split(/([+-])/); // Splits and keeps + or -

    const rows = [];

    for (let i = 0; i < tokens.length; i += 2) {
      const columnKey = tokens[i].trim(); // Get columnKey (odd index)
      const operator = tokens[i + 1]?.trim() || null; // Get operator (even index)

      if (!columnKey) {
        throw new Error("Invalid formula: missing columnKey.");
      }

      const columnData = alignedData[columnKey] || {};

      rows.push({
        columnKey,
        operator,
        type: columnData.type || null,
      });
    }

    // console.log(rows);
    return rows;
  };

  const saveCustomColumn = (formula, columnName, columnType) => {
    const currentTabKey = selectedTab || reportTabName;

    // Determine which column key to use
    const columnKeyToUpdate = editedColumnKey || availableCustomColumns[0];

    if (!columnKeyToUpdate) {
      console.error("No available custom columns");
      return;
    }

    // Create the updated aligned data for the current tab
    const updatedAlignedData = {
      ...tabsData[currentTabKey].alignedData,
      [columnKeyToUpdate]: {
        ...tabsData[currentTabKey].alignedData[columnKeyToUpdate],
        usedStatus: "used",
        formula: formula.toString(),
        label: columnName,
        type: columnType.toString(),
      },
    };

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey] = {
          ...updatedTabsData[currentTabKey],
          alignedData: updatedAlignedData,
        };
      }

      return updatedTabsData;
    });

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));

    setIsCustomColumnModalVisible(false);

    setEditedColumn(null);
    setEditedColumnKey(null);
    setColumnName("");
    setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
    setColumnType(null);
  };

  const handleEditColumn = (key, column) => {
    setEditedColumn(column);
    setEditedColumnKey(key);
    setColumnName(column.label || "");
    const initialRows = parseFormulaToRows(column.formula);
    setFormulaRows(initialRows);
    setColumnType(initialRows[0]?.type || null);
    setIsCustomColumnModalVisible(true);
  };

  const handleDeleteColumn = (column) => {
    // console.log(column, editedColumnKey, "HAAAA???");

    const currentTabKey = selectedTab || reportTabName;

    // Create the updated aligned data for the current tab
    const updatedAlignedData = {
      ...tabsData[currentTabKey].alignedData,
      [editedColumnKey]: {
        ...tabsData[currentTabKey].alignedData[editedColumnKey],
        usedStatus: "",
        formula: "",
        label: "",
        type: "",
      },
    };

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey] = {
          ...updatedTabsData[currentTabKey],
          alignedData: updatedAlignedData,
        };
      }

      return updatedTabsData;
    });

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));

    setIsCustomColumnModalVisible(false);

    setEditedColumn(null);
    setEditedColumnKey(null);
    setColumnName("");
    setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
    setColumnType(null);
  };

  let availableCustomColumns = Object.entries(alignedData || {}).reduce(
    (acc, [key, value]) => {
      if (key.includes("reportsCustom") && value?.formula === "") {
        acc.push(key);
      }
      return acc;
    },
    []
  );
  const handleSwitchChange = (checked) => {
    const currentTabKey = selectedTab || reportTabName;

    // Update columns' usedStatus excluding specific fields
    const updatedAlignedData = Object.fromEntries(
      Object.entries(tabsData[currentTabKey]?.alignedData || {}).map(
        ([key, value]) => {
          if (
            key === "entryID" ||
            key === "specTitle" ||
            key === "specRevision" ||
            key === "specNumber" ||
            unusedColumnsInViews.includes(key) ||
            (key.includes("reportsCustom") && alignedData[key].label === "")
          ) {
            return [key, value];
          }

          return [
            key,
            {
              ...value,
              usedStatus: checked ? "used" : "",
            },
          ];
        }
      )
    );

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };
      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey].alignedData = updatedAlignedData;
      }
      return updatedTabsData;
    });

    // Mark the tab as having unsaved changes
    setUnsavedTabs((prevUnsaved) => ({
      ...prevUnsaved,
      [currentTabKey]: true,
    }));

    // console.log(updatedAlignedData, "??");
  };

  const shouldIncludeKey = (key) => {
    return (
      key !== "entryID" &&
      key !== "specTitle" &&
      key !== "specRevision" &&
      key !== "specNumber" &&
      !unusedColumnsInViews.includes(key) &&
      !(key.includes("reportsCustom") && alignedData[key].label === "")
    );
  };

  const allUsed = Object.keys(alignedData)
    .filter(shouldIncludeKey)
    .every((key) => alignedData[key].usedStatus === "used");

  const numberOfTabs = [
    ...(reportTabs || []),
    ...(newReportTabs || []),
  ]?.filter((item) => !deletedTabs.includes(item));

  // console.log(numberOfTabs, "!?!??!");

  const reportNameAvailablity = [...(unavailableReportNames || [])]
    .filter((item) => item !== reportName)
    .includes(newReportName.trim())
    ? "error"
    : "";

  const groupingOptions = () => {
    return Object.entries(alignedData)
      .filter(([key, value]) => {
        return (
          value.label !== "" &&
          key !== "specRevision" &&
          key !== "entryID" &&
          key !== "group" &&
          value.usedStatus === "used" && // still testing
          !unusedColumnsInViews.includes(key)
        );
      })
      .map(([key, value]) => ({
        value: key,
        label: value.label,
      }))
      .sort((a, b) => a?.label?.localeCompare(b?.label));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (isNewTabModalVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isNewTabModalVisible]); // Runs when the modal visibility changes

  return (
    <>
      <Container>
        <div className="row mt-4">
          {isLoading && !accessLevel ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {/* When Request Fails  */}
              {!accessLevel ? (
                <div className="row">
                  <div className="text-center">
                    <Text>Something went wrong, please try again later.</Text>
                  </div>
                </div>
              ) : accessLevel < 2 ? (
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                // Main view
                <div className="d-flex" style={{ overflow: "hidden" }}>
                  <div
                    style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}
                  >
                    <>
                      <div className=" bg-white p-2  py-3 rounded-3 mb-2">
                        <div className=" d-flex justify-content-between ">
                          <div className=" d-flex flex-column gap-2">
                            <div>
                              <small>
                                Report Name{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </small>
                              <Input
                                maxLength={30}
                                showCount
                                defaultValue={
                                  reportPageTitle ? reportPageTitle : ""
                                }
                                status={reportNameAvailablity}
                                onChange={(e) => {
                                  setNewReportName(e.target.value);
                                  if (selectedTab) {
                                    setUnsavedTabs((prev) => ({
                                      ...prev,
                                      [selectedTab]: true,
                                    }));
                                  }
                                }}
                                placeholder="Report Name"
                              />
                              {reportNameAvailablity === "error" && (
                                <div>
                                  <small
                                    className="mt-1"
                                    style={{ color: "red" }}
                                  >
                                    A report with this name already exists.
                                  </small>
                                </div>
                              )}
                            </div>
                            <div>
                              <small>Report Description</small>
                              <Input
                                maxLength={100}
                                showCount
                                defaultValue={
                                  reportDescription ? reportDescription : ""
                                }
                                onChange={(e) => {
                                  setNewReportDescription(e.target.value);
                                  if (selectedTab) {
                                    setUnsavedTabs((prev) => ({
                                      ...prev,
                                      [selectedTab]: true,
                                    }));
                                  }
                                }}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-4 flex-column w-25">
                            <div className="align-self-end">
                              <Button
                                onClick={() => {
                                  let email = localStorage.getItem("email");
                                  const cancelUrl = `/main/company/${company_id}/projects/${id}/project-custom-reports/${encodeURIComponent(
                                    reportName
                                  )}?owner=${encodeURIComponent(
                                    email
                                  )}&tab=${encodeURIComponent(
                                    reportTabs[0] || ""
                                  )}`;
                                  navigate(cancelUrl);
                                }}
                                type="link"
                                className="me-1"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={saveReport}
                                type="primary"
                                disabled={
                                  (!reportName && !newReportName) ||
                                  reportNameAvailablity === "error" ||
                                  numberOfTabs.length === 0 ||
                                  (Object.keys(unsavedTabs).length === 0 &&
                                    deletedTabs.length === 0)
                                }
                              >
                                Save Report
                              </Button>
                            </div>
                            <div className=" d-flex flex-column gap-1 w-100">
                              <small>Group Columns:</small>
                              <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Columns"
                                disabled={
                                  isLoading ||
                                  numberOfTabs.length === 0 ||
                                  !selectedTab
                                }
                                value={groupedColumns}
                                onChange={handleGroupingChange}
                                maxTagCount={1}
                                options={groupingOptions()}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <hr /> */}
                      </div>

                      <Tabs
                        type="editable-card"
                        onChange={onTabChange}
                        onEdit={onEdit}
                        tabBarExtraContent={
                          <div className=" d-flex gap-1 align-items-center justify-content-end">
                            {showNote && (
                              <div
                                className=" p-2 d-flex justify-content-end align-items-center "
                                style={{
                                  marginBottom: "20px",
                                }}
                              >
                                <Alert
                                  type="warning"
                                  // message="Save to see applied changes."
                                  message="Save to see applied changes, to apply filters, or sortings."
                                  style={{ fontSize: "12px" }}
                                  closable
                                ></Alert>
                              </div>
                            )}
                            <Button
                              onClick={() => {
                                setFilteredInfo({});

                                // update tab data
                                setTabsData((prev) => {
                                  const currentTabKey =
                                    selectedTab || reportTabName;
                                  const updatedTabsData = { ...prev };

                                  updatedTabsData[currentTabKey] = {
                                    ...updatedTabsData[currentTabKey],
                                    alignedData: Object.fromEntries(
                                      Object.entries(
                                        updatedTabsData[currentTabKey]
                                          ?.alignedData || {}
                                      ).map(([key, value]) => [
                                        key,
                                        {
                                          ...value,
                                          filtering: "",
                                        },
                                      ])
                                    ),
                                  };

                                  return updatedTabsData;
                                });

                                setUnsavedTabs((prev) => ({
                                  ...prev,
                                  [selectedTab]: true,
                                }));
                              }}
                              style={{
                                marginBottom: "20px",
                              }}
                              disabled={
                                isFilteredInfoEmpty(filteredInfo) ||
                                groupedColumns.length > 0
                              }
                            >
                              Clear Filters
                            </Button>
                          </div>
                        }
                        activeKey={selectedTab ? selectedTab : reportTabName}
                        className="rounded-3"
                        addIcon={
                          <div
                            style={{
                              width: "30px",
                              height: "42px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PlusOutlined />
                          </div>
                        }
                        tabBarStyle={{
                          marginBottom: "-1px",
                        }}
                        style={{
                          background: "white",
                          padding: "20px 10px 10px 10px ",
                        }}
                        items={[...(reportTabs || []), ...(newReportTabs || [])]
                          ?.filter((item) => !deletedTabs.includes(item))
                          ?.map((item, index) => ({
                            label: (
                              <div className="d-flex align-items-center gap-2">
                                {selectedTab === item && (
                                  <SettingFilled
                                    onClick={() => {
                                      if (!isLoading) {
                                        handleRenameTab(item);
                                      }
                                    }}
                                    style={{
                                      padding: "0px",
                                      marginRight: "0px",
                                      cursor: isLoading
                                        ? "not-allowed"
                                        : "pointer",
                                      color: "black",
                                    }}
                                  />
                                )}
                                <span>
                                  {tabsData[item]?.newTabName || item}
                                </span>
                              </div>
                            ),
                            key: item,
                            children: (
                              <div>
                                <DndContext
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={handleDragEnd}
                                >
                                  <SortableContext
                                    items={columns.map((col) => col.key)}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    <Table
                                      columns={columns.map((column, index) => ({
                                        ...column,
                                        title:
                                          column.key === "specTitle" ||
                                          column.key === "specNumber" ||
                                          column.key === "specRevision" ||
                                          column.key === "group" ? (
                                            column.title
                                          ) : (
                                            <SortableColumnTitle
                                              id={column.key}
                                            >
                                              {column.title}
                                            </SortableColumnTitle>
                                          ),
                                      }))}
                                      dataSource={dataSource}
                                      scroll={{ x: 500, y: "70vh" }}
                                      bordered
                                      loading={
                                        isLoading
                                          ? {
                                              indicator: <LoadingComponent />,
                                            }
                                          : false
                                      }
                                      pagination={{
                                        position: ["bottomRight"],
                                        defaultPageSize: 10,
                                      }}
                                      onChange={handleChange}
                                      size="small"
                                    />
                                  </SortableContext>
                                </DndContext>
                              </div>
                            ),
                          }))}
                      />

                      {[...(reportTabs || []), ...(newReportTabs || [])]
                        .length === 0 && (
                        <div className="d-flex flex-column align-items-center justify-content-center p-4 bg-white h-25 mt-n2">
                          <PlusCircleFilled
                            className="mb-2 opacity-75"
                            style={{ fontSize: "28px" }}
                          />
                          {/* <h1 className="h4 mb-4">Get Started</h1> */}
                          <small className="text-muted mb-2">
                            Create a new tab to start building your report
                          </small>
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsNewTabModalVisible(true);
                            }}
                          >
                            Create New Tab
                          </Button>
                        </div>
                      )}

                      <Modal
                        key={
                          isNewTabModalVisible ? "modal-open" : "modal-closed"
                        } // to force useEffect to re-render to autofocus input
                        title={"Tab Name"}
                        open={isNewTabModalVisible}
                        onOk={handleNewTabConfirm}
                        onCancel={() => {
                          setIsNewTabModalVisible(false);
                          setRenameFlag(false);
                          setNewTabName("");
                        }}
                        okButtonProps={{
                          disabled:
                            !newTabName.trim() ||
                            [
                              ...(reportTabs || []),
                              ...(newReportTabs || []),
                              ...(deletedTabs || []),
                              ...(newRenamedTabsArray || []),
                            ].includes(newTabName.trim()),
                        }}
                      >
                        <Input
                          ref={inputRef}
                          placeholder="Enter tab name"
                          value={newTabName}
                          onChange={(e) => setNewTabName(e.target.value)}
                          maxLength={30}
                          showCount
                          status={
                            [
                              ...(reportTabs || []),
                              ...(newReportTabs || []),
                              ...(deletedTabs || []),
                              ...(newRenamedTabsArray || []),
                            ].includes(newTabName.trim())
                              ? "error"
                              : ""
                          }
                        />
                        {[
                          ...(reportTabs || []),
                          ...(newReportTabs || []),
                          ...(deletedTabs || []),
                          ...(newRenamedTabsArray || []),
                        ].includes(newTabName.trim()) && (
                          <div className=" mb-1">
                            <div>
                              <small className="mt-1" style={{ color: "red" }}>
                                Tab with this name already exists.
                              </small>
                            </div>
                          </div>
                        )}
                      </Modal>

                      <Modal
                        title="Custom Column"
                        open={isCustomColumnModalVisible}
                        onCancel={() => {
                          setEditedColumn(null);
                          setEditedColumnKey(null);
                          setIsCustomColumnModalVisible(false);
                          setColumnName("");
                          setFormulaRows([
                            { columnKey: "", operator: "+", type: null },
                          ]); // Reset formula
                          setColumnType(null);
                        }}
                        footer={null}
                      >
                        <CreateCustomColumn
                          columnName={columnName}
                          columnType={columnType}
                          setColumnType={setColumnType}
                          setColumnName={setColumnName}
                          formulaRows={formulaRows}
                          setFormulaRows={setFormulaRows}
                          column={editedColumn}
                          alignedData={alignedData}
                          unusedColumnsInViews={unusedColumnsInViews}
                          editedColumn={editedColumn}
                          editedColumnKey={editedColumnKey}
                          parseFormulaToRows={parseFormulaToRows}
                          saveCustomColumn={saveCustomColumn}
                          handleDeleteColumn={handleDeleteColumn}
                          setShowNote={setShowNote}
                          groupedColumns={groupedColumns}
                        />
                      </Modal>
                    </>
                  </div>
                  {/* Right-side scrollable div */}
                  <div
                    className="rounded-3"
                    style={{
                      width: "300px",
                      overflowY: "auto",
                      background: "#FAFAFA",
                      height: "90vh",
                      position: "relative",
                    }}
                  >
                    <div
                      className=" d-flex flex-column gap-3 w-100 pt-3 px-3 pb-3 border-bottom mb-2 bg-white "
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        background: "#FFFFFF",
                      }}
                    >
                      <div className=" w-100">
                        <Button
                          type="primary"
                          className="w-100"
                          onClick={() => {
                            setIsCustomColumnModalVisible(true);
                          }}
                          disabled={
                            availableCustomColumns.length < 1 ||
                            !selectedTab ||
                            numberOfTabs.length === 0 ||
                            isLoading
                          }
                        >
                          Create Custom Column
                        </Button>
                      </div>
                      <Input
                        placeholder="Search Columns"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={
                          !selectedTab || numberOfTabs.length === 0 || isLoading
                        }
                        suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                      />
                      <div className=" d-flex justify-content-between align-items-center">
                        <small className="fw-semibold">Toggle All</small>
                        <Switch
                          checked={allUsed}
                          size="small"
                          disabled={
                            !selectedTab ||
                            numberOfTabs.length === 0 ||
                            isLoading
                          }
                          onChange={handleSwitchChange}
                        />
                      </div>
                    </div>
                    <div className=" px-3">
                      {filteredcolumnsInfo &&
                      selectedTab &&
                      numberOfTabs.length > 0 ? (
                        filteredcolumnsInfo.map(([key, value], index) => (
                          <div
                            key={key}
                            className="d-flex justify-content-between align-items-center"
                            style={{
                              borderBottom: "1px solid #e8e8e8",
                              paddingBottom: "8px",
                              paddingTop: index !== 0 ? "8px" : "3px",
                            }}
                          >
                            <div className=" d-flex align-items-center gap-2">
                              {key.includes("reportsCustom") && (
                                <>
                                  <Tooltip
                                    title="Edit Column"
                                    style={{
                                      cursor: isLoading
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  >
                                    <SettingFilled
                                      onClick={() => {
                                        if (!isLoading) {
                                          handleEditColumn(key, value);
                                        }
                                      }}
                                      style={{
                                        fontSize: "14px",
                                        cursor: isLoading
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )}
                              <small>{value.label}</small>
                            </div>
                            <Switch
                              checked={
                                value.usedStatus === "used" ||
                                groupedColumns.includes(key)
                              }
                              disabled={
                                key === "specTitle" ||
                                key === "specRevision" ||
                                key === "specNumber" ||
                                !selectedTab ||
                                numberOfTabs.length === 0 ||
                                groupedColumns.includes(key) ||
                                isLoading
                              }
                              size="small"
                              onChange={(checked) => {
                                const currentTabKey =
                                  selectedTab || reportTabName;

                                const updatedsAlignedData = {
                                  ...tabsData[currentTabKey]?.alignedData,
                                  [key]: {
                                    ...tabsData[currentTabKey]?.alignedData?.[
                                      key
                                    ],
                                    usedStatus: checked ? "used" : "",
                                  },
                                };
                                setAlignedData(updatedsAlignedData);

                                setTabsData((prev) => {
                                  const updatedTabsData = { ...prev };

                                  if (!updatedTabsData[currentTabKey]) {
                                    updatedTabsData[currentTabKey] = {};
                                  }
                                  updatedTabsData[currentTabKey].alignedData =
                                    updatedsAlignedData;

                                  setUnsavedTabs((prevUnsaved) => ({
                                    ...prevUnsaved,
                                    [currentTabKey]: true,
                                  }));

                                  return updatedTabsData;
                                });
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                          <SmileOutlined
                            style={{ fontSize: 20 }}
                            className="text-muted mb-1"
                          />
                          <p className=" text-muted">No Data Available</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
