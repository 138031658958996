export const ReportsEndPoints = {
  getReportDetails: {
    url: (
      company,
      project,
      firstColumnName,
      secondColumnName,
      minValue,
      maxValue,
      sortBy,
      status,
      filteredColumns,
      filterCombination,
      subcontractorFilter,
      reportRequestName
    ) => `/rest/v1/${reportRequestName}/${company}/${project}`,
    method: `POST`,
  },

  getReportAccessLevel: {
    url: (company, project) =>
      `/rest/v1/reportPageAccessLevel/${company}/${project}`,
    method: `GET`,
  },

  modifyIssueDateReport: {
    url: (company, project) =>
      `/rest/v1/modifyIssueDateReport/${company}/${project}`,
    method: `POST`,
  },

  // CUSTOM REPORTS
  readAllCustomReportsInfo: {
    url: (company, project) =>
      `/rest/v1/readAllCustomReportsInfo/${company}/${project}`,
    method: `GET`,
  },

  readSharedCustomReport: {
    url: (company, project) =>
      `/rest/v1/readSharedCustomReport/${company}/${project}`,
    method: `GET`,
  },

  readCustomReport: {
    url: (company, project) =>
      `/rest/v1/readCustomReport/${company}/${project}`,
    method: `POST`,
  },

  getDataToCreateUpdateCustomReport: {
    url: (company, project) =>
      `/rest/v1/getDataToCreateUpdateCustomReport/${company}/${project}`,
    method: `POST`,
  },

  createCustomReport: {
    url: (company, project) =>
      `/rest/v1/createCustomReport/${company}/${project}`,
    method: `POST`,
  },

  updateCustomReport: {
    url: (company, project) =>
      `/rest/v1/updateCustomReport/${company}/${project}`,
    method: `PUT`,
  },
  deleteCustomReport: {
    url: (company, project) =>
      `/rest/v1/deleteCustomReport/${company}/${project}`,
    method: `DELETE`,
  },
  cloneCustomReport: {
    url: (company, project) =>
      `/rest/v1/cloneCustomReport/${company}/${project}`,
    method: `POST`,
  },
  shareCustomReport: {
    url: (company, project) =>
      `/rest/v1/shareCustomReport/${company}/${project}`,
    method: `POST`,
  },
  deleteSharedCustomReport: {
    url: (company, project) =>
      `/rest/v1/deleteSharedCustomReport/${company}/${project}`,
    method: `DELETE`,
  },
};
