import { Input, Modal } from "antd";
import { ReportServices } from "apis/Services/ReportService";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { toast } from "react-hot-toast";

function CloneReports({
  isVisible,
  reportName,
  reportOwner,
  reportDescription,
  onCancel,
  fetchCustomReports,
  fetchSharedCustomReports,
  allReportNames,
}) {
  const { company_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [newReportName, setNewReportName] = useState("");
  const [error, setError] = useState("");

  function cloneReport(
    reportName,
    newReportName,
    reportDescription,
    reportOwner
  ) {
    const toastId = toast.loading("Cloning Report...");
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName,
      newReportName: newReportName,
      reportDescription: reportDescription,
      reportOwner: reportOwner,
    };

    ReportServices.cloneCustomReport(ReqObj)
      .then((res) => {
        fetchCustomReports();
        toast.success("Report cloned successfully", { id: toastId });
        // fetchSharedCustomReports();
      })
      .catch((err) => {
        console.log("Error cloning report:", err);
        toast.error("Something went wrong, please try again.", {
          id: toastId,
        });
      })
      .finally(() => {
        setNewReportName("");
        setIsLoading(false);
        onCancel();
      });
  }

  // console.log(allReportNames, "?!?!?");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewReportName(value);

    if (allReportNames.includes(value.trim())) {
      setError("This name is already taken. Please choose a different name.");
    } else {
      setError("");
    }
  };

  return (
    <Modal
      title="Clone Report"
      open={isVisible}
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setNewReportName("");
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="clone"
          type="primary"
          onClick={() =>
            cloneReport(
              reportName,
              newReportName,
              reportDescription,
              reportOwner
            )
          }
          loading={isLoading}
          disabled={!newReportName || error}
        >
          Clone
        </Button>,
      ]}
    >
      <p>
        Please enter a name for the cloned report based on{" "}
        <strong>{reportName}</strong>:
      </p>
      <Input
        placeholder="Enter new report name"
        value={newReportName}
        maxLength={30}
        onChange={handleInputChange}
      />
      {error && (
        <small style={{ color: "red" }}>
          This name is already taken. Please choose a different name.
        </small>
      )}
    </Modal>
  );
}

export default CloneReports;
