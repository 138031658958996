import React, { useState, useEffect } from "react";
import { Select, Button, Input, Modal } from "antd";

function CreateCustomColumn({
  columnName,
  columnType,
  setColumnType,
  setColumnName,
  formulaRows,
  setFormulaRows,
  column,
  alignedData,
  unusedColumnsInViews,
  editedColumn,
  editedColumnKey,
  parseFormulaToRows,
  saveCustomColumn,
  handleDeleteColumn,
  setShowNote,
  groupedColumns,
}) {
  const [formula, setFormula] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    if (column && column.formula) {
      // Set the initial formula rows based on the column's formula
      // console.log(column, "??");

      const initialRows = parseFormulaToRows(column.formula);
      setFormulaRows(initialRows);
      setColumnName(column.label || "");
      setColumnType(initialRows[0]?.type || null);
    }
  }, [column]);

  // useEffect(() => {
  //   console.log(formulaRows, "????");
  // }, [formulaRows]);

  // Filter options based on the first column's type and rules
  const calculatedOptions = () => {
    return Object.entries(alignedData)
      .filter(([key, value]) => {
        return (
          value.label !== "" &&
          value.type !== "string" &&
          key !== "specRevision" &&
          key !== column &&
          !unusedColumnsInViews.includes(key) &&
          value?.label !== column?.label // testing removing the same column we're editing from the dropdown list
        );
      })
      .map(([key, value]) => ({
        value: key,
        label: value.label,
        type: value.type,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  // Determine allowed options for subsequent columns
  const getAllowedOptions = (index) => {
    const options = calculatedOptions();
    const firstColumn = formulaRows[0];

    // Check if the formula includes a date and has more than 2 rows
    const hasDate = formulaRows.some((row) => row.type === "date");
    const hasMoreThanTwoRows = formulaRows.length > 2;

    // If the formula includes a date and has more than 2 rows, hide date options
    if (hasDate && hasMoreThanTwoRows) {
      return options.filter((option) => option.type !== "date");
    }

    // Specific rules based on the first column
    if (firstColumn.type === "date" && firstColumn.operator === "-") {
      return options.filter(
        (option) =>
          (option.type === "date" || option.type === "int") &&
          option.value !== firstColumn.columnKey
      );
    }

    if (firstColumn.type === "date" && firstColumn.operator === "+") {
      return options.filter((option) => option.type === "int");
    }

    if (firstColumn.type === "int") {
      return options.filter((option) => option.type === "int");
    }

    return [];
  };

  // Handle first column selection
  const handleFirstColumnSelect = (value, option) => {
    const updatedRows = [...formulaRows];
    updatedRows[0] = {
      columnKey: option?.value,
      operator: updatedRows[0].operator,
      type: option?.type,
    };

    // Add a new row with an operator when a column is selected
    if (formulaRows.length < 2) {
      updatedRows.push({
        columnKey: "",
        operator: "+",
        type: null,
      });
    }

    setFormulaRows(updatedRows);
    setColumnType(option?.type);
  };

  // Handle first row operator change
  const handleFirstOperatorChange = (operator) => {
    const updatedRows = [...formulaRows];
    updatedRows[0].operator = operator;

    // Clear rows after the first column when operator changes
    if (updatedRows.length > 1) {
      // Keep only the first row and reset subsequent rows
      updatedRows.splice(1);
      updatedRows.push({
        columnKey: "",
        operator: "+",
        type: null,
      });
    }

    setFormulaRows(updatedRows);

    // setColumnType(null);
  };

  // Handle other column selections
  const handleSubsequentColumnSelect = (index, value, option) => {
    const updatedRows = [...formulaRows];

    // Update the selected column for the specific row
    updatedRows[index] = {
      columnKey: option?.value,
      operator: updatedRows[index].operator,
      type: option?.type,
    };

    // add a new row if this is the last row
    if (index === updatedRows.length - 1) {
      if (
        option?.type !== "date" ||
        (option?.type === "date" && updatedRows[0].operator !== "-")
      ) {
        updatedRows.push({
          columnKey: "",
          operator: "+",
          type: null,
        });
      }
    }

    setFormulaRows(updatedRows);
  };

  const handleOperatorChange = (index, operator) => {
    const updatedRows = [...formulaRows];
    updatedRows[index].operator = operator;

    setFormulaRows(updatedRows);
  };

  const handleRemoveRow = (index) => {
    if (formulaRows.length > 2) {
      const updatedRows = [...formulaRows];
      updatedRows.splice(index, 1);
      setFormulaRows(updatedRows);
    }
  };

  const handleSave = () => {
    const formulaString = formulaRows
      .filter((row) => row.columnKey)
      .reduce((acc, row, index) => {
        if (index === 0) return row.columnKey;
        return `${acc}${formulaRows[index - 1].operator}${row.columnKey}`;
      }, "");

    if (
      formulaRows.length === 2 &&
      formulaRows[0].type === "date" &&
      formulaRows[1].type === "date"
    ) {
      columnType = "int";
    }

    // console.log(formulaString, columnName, columnType, "!?!??!");

    saveCustomColumn(formulaString, columnName, columnType);

    setShowNote(true);

    // Reset form or perform further actions
    setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
    setColumnName("");
    setColumnType(null);
  };

  const showDeleteConfirmModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    if (handleDeleteColumn && column) {
      handleDeleteColumn(column);
      setIsDeleteModalVisible(false);

      // Reset form states
      setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
      setColumnName("");
      setColumnType(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-2 mb-2">
        <div className="w-100 d-flex flex-column gap-1">
          <strong>
            Column Name <strong style={{ color: "red" }}>*</strong>
          </strong>
          <Input
            className="w-100"
            placeholder="Column Name"
            value={columnName}
            onChange={(e) => setColumnName(e.target.value)}
          />
        </div>
      </div>

      <strong>Column Formula</strong>
      {formulaRows.map((row, index) => (
        <div key={index} className="d-flex gap-2 mb-2">
          <Select
            placeholder="Select Column"
            style={{ width: 310 }}
            value={row.columnKey}
            options={
              index === 0
                ? calculatedOptions()
                : getAllowedOptions(index).filter(
                    (option) => option.columnKey !== formulaRows[0].columnKey
                  )
            }
            onChange={(value, option) =>
              index === 0
                ? handleFirstColumnSelect(value, option)
                : handleSubsequentColumnSelect(index, value, option)
            }
            onSearch={(value) => {
              // console.log('Search value:', value);
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />

          {index === 0 && (
            <Select
              style={{ width: 60 }}
              value={row.operator}
              onChange={handleFirstOperatorChange}
            >
              <Select.Option value="+">+</Select.Option>
              <Select.Option value="-">-</Select.Option>
            </Select>
          )}

          {index > 0 &&
            row.operator &&
            !(index === formulaRows.length - 1 && row.type === "date") && (
              <>
                <Select
                  style={{ width: 60 }}
                  value={row.operator}
                  onChange={(value) => handleOperatorChange(index, value)}
                >
                  <Select.Option value="+">+</Select.Option>
                  <Select.Option value="-">-</Select.Option>
                </Select>

                {index > 1 && (
                  <Button onClick={() => handleRemoveRow(index)} type="ghost">
                    Remove
                  </Button>
                )}
              </>
            )}
        </div>
      ))}

      <div className=" d-flex justify-content-between">
        <div>
          {column && (
            <Button
              type="primary"
              danger
              onClick={showDeleteConfirmModal}
              disabled={!column || groupedColumns.includes(editedColumnKey)}
            >
              Delete Column
            </Button>
          )}
        </div>
        <div className=" d-flex flex-row-reverse gap-2">
          <Button
            type="primary"
            onClick={handleSave}
            disabled={
              !columnName ||
              formulaRows.length < 2 ||
              !formulaRows[0].columnKey ||
              !formulaRows[1].columnKey
            }
          >
            Save
          </Button>
          <Button
            type="default"
            onClick={() => {
              setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
              setColumnType(null);
            }}
            disabled={formulaRows.length === 1}
          >
            Clear Formula
          </Button>
        </div>
      </div>

      <Modal
        title="Confirm Column Deletion"
        open={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete the column "{columnName}"?</p>
        <p>This action cannot be undone.</p>
      </Modal>
    </div>
  );
}

export default CreateCustomColumn;
