import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./custom-reports-details.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import {
  Table,
  Tooltip,
  DatePicker,
  Button,
  Tabs,
  Input,
  Select,
  Tag,
  Breadcrumb,
} from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

import {
  CopyOutlined,
  LeftOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CloneReports from "components/customReports/clone-reports.js";
import ShareReport from "components/customReports/share-reports";

export default function CustomReportsDetails() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [editedData, setEditedData] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let reportPageTitle = reportName;
  const reportOwner = searchParams.get("owner");
  let reportTabName = searchParams.get("tab");
  let email = localStorage.getItem("email");

  const [data, setData] = useState([]);

  const [reportDescription, setReportDescription] = useState("");
  const [alignedData, setAlignedData] = useState({});
  const [reportTabs, setReportTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [contactsDictionary, setContactsDictionary] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [sharedListInfo, setSharedListInfo] = useState([]);
  const [allReportNames, setAllReportNames] = useState([]);
  const [groupedColumns, setGroupedColumns] = useState([]);
  const [exportedData, setExportedData] = useState([]);

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName,
      reportTabName,
      reportOwner,
    };
    ReportServices.readCustomReport(ReqObj)
      .then((res) => {
        setReportDescription(res?.reportInfo?.reportDescription);
        setReportTabs(res?.reportInfo?.reportTabs);
        setData(res?.procurementLogEntries);
        setAccessLevel(parseInt(res?.userData?.accessLevel));
        setProjectName(res?.projectData?.projectName);
        setProjectAddress(res?.projectData?.projectAddress);
        setAlignedData(res?.reportsAlignedData);
        setContactsDictionary(res?.contactsDictionary);
        setSharedListInfo(res?.reportInfo?.sharedListInfo);
        setGroupedColumns(res?.groupedColumnsLabels);
        setAllReportNames(res?.usedReportNames);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  // function to sort submittal numbers
  const parseSubmittalNumber = (number) => {
    return number
      .split(/[^0-9]+/)
      .filter(Boolean) // remove "-"
      .map(Number);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let columns = [];

  if (alignedData?.group) {
    columns.push({
      title: "Group",
      dataIndex: "group",
      key: "group",
      align: "left",
      width: 300,
      fixed: "left",
      defaultSortOrder:
        alignedData?.itemName?.sorting === "Descending"
          ? "descend"
          : alignedData?.itemName?.sorting === "Ascending"
          ? "ascend"
          : "",
      render: (text) => {
        return <span style={{ fontWeight: "bold" }}>{text}</span>;
      },
    });
  }

  columns = [
    ...columns,
    {
      title: "Submittal Title",
      dataIndex: "itemName",
      key: "itemName",
      align: "left",
      width: 400,
      fixed: "left",
      defaultSortOrder:
        alignedData?.itemName?.sorting === "Descending"
          ? "descend"
          : alignedData?.itemName?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => {
        const isEmptyRow = (row) => {
          return !row.group && !row.itemName;
        };
        if (groupedColumns.length === 0) {
          return a.itemName.localeCompare(b.itemName);
        }
        if (a.group || b.group) return 0;

        // Only sort if both rows are under the same parent group
        if (a.parentGroup === b.parentGroup) {
          const aEmpty = isEmptyRow(a);
          const bEmpty = isEmptyRow(b);

          // If both empty or both non-empty, sort normally
          if (aEmpty === bEmpty) {
            if (a.itemName && b.itemName) {
              return a.itemName.localeCompare(b.itemName);
            }
            return 0;
          }
          if (aEmpty) return -1;
          if (bEmpty) return 1;
        }
        return 0;
      },
    },
    {
      title: "Submittal Number",
      dataIndex: "submittalNumber",
      key: "submittalNumber",
      align: "center",
      width: 150,
      fixed: "left",
      defaultSortOrder:
        alignedData?.specNumber?.sorting === "Descending"
          ? "descend"
          : alignedData?.specNumber?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => {
        const isEmptyRow = (row) => {
          return !row.group && !row.submittalNumber;
        };
        if (groupedColumns.length === 0) {
          return a.submittalNumber.localeCompare(b.submittalNumber);
        }
        if (a.group || b.group) return 0;

        // Only sort if both rows are under the same parent group
        if (a.parentGroup === b.parentGroup) {
          const aEmpty = isEmptyRow(a);
          const bEmpty = isEmptyRow(b);

          // If both empty or both non-empty, sort normally
          if (aEmpty === bEmpty) {
            if (a.submittalNumber && b.submittalNumber) {
              return parseSubmittalNumber(a.submittalNumber)
                .toString()
                .localeCompare(
                  parseSubmittalNumber(b.submittalNumber).toString(),
                  undefined,
                  { numeric: true }
                );
            }
            return 0;
          }
          // Empty rows are placed to the bottom or top of the group
          if (aEmpty) return -1;
          if (bEmpty) return 1;
        }
        return 0;
      },
      // sorter:
      //   groupedColumns.length === 0
      //     ? (a, b) => a.submittalNumber.localeCompare(b.submittalNumber)
      //     : false,
    },
    {
      title: "Submittal Revison",
      dataIndex: "submittalRevison",
      key: "submittalRevison",
      align: "center",
      width: 150,
      fixed: "left",
      defaultSortOrder:
        alignedData?.submittalRevison?.sorting === "Descending"
          ? "descend"
          : alignedData?.submittalRevison?.sorting === "Ascending"
          ? "ascend"
          : "",
      sorter: (a, b) => {
        const isEmptyRow = (row) => {
          return !row.group && !row.submittalRevison;
        };
        if (groupedColumns.length === 0) {
          return Number(a.submittalRevison) - Number(b.submittalRevison);
        }
        if (a.group || b.group) return 0;

        // Only sort if both rows are under the same parent group
        if (a.parentGroup === b.parentGroup) {
          const aEmpty = isEmptyRow(a);
          const bEmpty = isEmptyRow(b);

          // If both empty or both non-empty, sort normally
          if (aEmpty === bEmpty) {
            if (a.submittalRevison && b.submittalRevison) {
              return Number(a.submittalRevison) - Number(b.submittalRevison);
            }
            return 0;
          }
          // Empty rows are placed to the bottom or top of the group
          if (aEmpty) return -1;
          if (bEmpty) return 1;
        }
        return 0;
      },
      // sorter:
      //   groupedColumns.length === 0
      //     ? (a, b) => Number(a.submittalRevison) - Number(b.submittalRevison)
      //     : false,
    },
    ...Object.entries(alignedData)
      .filter(
        ([key]) =>
          ![
            "specTitle",
            "specNumber",
            "specRevision",
            "group",
            // "specSection",
          ].includes(key)
      )
      .sort((a, b) => a[1].index - b[1].index)
      .map(([key, value]) => {
        const uniqueValues = [...new Set(dataSource.map((item) => item[key]))];
        const columnConfig = {
          title: value.label || key,
          dataIndex: key,
          key: key,
          align: "center",
          filterMode: "tree",
          filterSearch: true,
          filteredValue: filteredInfo[key] || null,
          // defaultSortOrder:
          //   alignedData?.[key]?.sorting === "Descending"
          //     ? "descend"
          //     : alignedData?.[key]?.sorting === "Ascending"
          //     ? "ascend"
          //     : "",
          width: 200,
        };

        if (value.type === "int") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));

          if (groupedColumns.length === 0) {
            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];
              if (filterValue === "") {
                return cellValue === "";
              }
              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const numA = Number(a[key]);
              const numB = Number(b[key]);
              return numA - numB;
            };
          } else {
            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];

              if (filterValue === "") {
                return cellValue === "";
              }
              if (record.group) {
                return true;
              }

              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row[key];
              };
              if (a.group || b.group) return 0;

              // Only sort if both rows are under the same parent group
              if (a.parentGroup === b.parentGroup) {
                const aEmpty = isEmptyRow(a);
                const bEmpty = isEmptyRow(b);

                // If both empty or both non-empty, sort normally
                if (aEmpty === bEmpty) {
                  if (a[key] && b[key]) {
                    return Number(a[key]) - Number(b[key]);
                  }
                  return 0;
                }
                // Empty rows always go to bottom
                if (aEmpty) return 1;
                if (bEmpty) return -1;
              }
              return 0;
            };
          }
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "string") {
          columnConfig.filters = uniqueValues
            .filter((v) => v !== undefined && v !== null)
            .map((uniqueValue) => ({
              text: uniqueValue !== "" ? uniqueValue : "[Blank]",
              value: uniqueValue !== "" ? uniqueValue : "",
            }));
          if (groupedColumns.length === 0) {
            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];
              if (filterValue === "") {
                return cellValue === "";
              }
              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const strA = String(a[key]);
              const strB = String(b[key]);
              return strA.localeCompare(strB);
            };
          } else {
            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];

              if (filterValue === "") {
                return cellValue === "";
              }
              if (record.group) {
                return true;
              }

              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row[key];
              };
              if (a.group || b.group) return 0;

              // Only sort if both rows are under the same parent group
              if (a.parentGroup === b.parentGroup) {
                const aEmpty = isEmptyRow(a);
                const bEmpty = isEmptyRow(b);

                // If both empty or both non-empty, sort normally
                if (aEmpty === bEmpty) {
                  if (a[key] && b[key]) {
                    const strA = String(a[key]);
                    const strB = String(b[key]);
                    return strA.localeCompare(strB);
                  }
                  return 0;
                }
                // Empty rows always go to bottom
                if (aEmpty) return 1;
                if (bEmpty) return -1;
              }
              return 0;
            };
          }
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
        } else if (value.type === "date") {
          columnConfig.filterDropdown = ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            const handleConfirm = () => {
              if (startDate && endDate) {
                const formattedStartDate = startDate.format("MM-DD-YYYY");
                const formattedEndDate = endDate.format("MM-DD-YYYY");

                setFilteredInfo((prev) => ({
                  ...prev,
                  [key]: {
                    startDate: startDate,
                    endDate: endDate,
                  },
                }));

                setSelectedKeys([formattedStartDate, formattedEndDate]);

                confirm();
              }
            };

            const handleReset = () => {
              setStartDate(null);
              setEndDate(null);
              setSelectedKeys([]);
              clearFilters();
              confirm();
            };

            return (
              <div style={{ padding: 8 }}>
                <div>
                  <strong style={{ width: "50%", marginBottom: 8 }}>
                    Pick a range
                  </strong>
                </div>
                <div className="d-flex gap-2 justify-content-between">
                  <DatePicker
                    // value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="Start Date"
                  />
                  <DatePicker
                    // value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="MM-DD-YYYY"
                    style={{ width: "50%", marginBottom: 8 }}
                    placeholder="End Date"
                  />
                </div>
                <div className="d-flex justify-content-between flex-row-reverse">
                  <Button
                    type="primary"
                    onClick={handleConfirm}
                    size="small"
                    disabled={!startDate || !endDate}
                  >
                    OK
                  </Button>
                  <Button
                    onClick={handleReset}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            );
          };
          if (groupedColumns.length === 0) {
            columnConfig.onFilter = (value, record) => {
              const recordDate = moment(record[key], "MM-DD-YYYY");

              if (!recordDate.isValid()) {
                return false;
              }

              let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
              let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

              const formattedStartDate = startDate
                ? moment(startDate, "MM-DD-YYYY")
                : null;
              const formattedEndDate = endDate
                ? moment(endDate, "MM-DD-YYYY")
                : null;
              if (formattedStartDate && !formattedStartDate.isValid()) {
                console.error("Invalid start date:", startDate);
                return false;
              }

              if (formattedEndDate && !formattedEndDate.isValid()) {
                console.error("Invalid end date:", endDate);
                return false;
              }

              const isDateInRange = recordDate.isBetween(
                formattedStartDate,
                formattedEndDate,
                null,
                "[]"
              );

              return isDateInRange;
            };

            columnConfig.sorter = (a, b) => {
              const dateA = a[key] ? dayjs(a[key]) : null;
              const dateB = b[key] ? dayjs(b[key]) : null;

              if (dateA && dateB) {
                return dateA.diff(dateB);
              }

              if (!dateA) return 1;
              if (!dateB) return -1;

              return 0;
            };
          } else {
            columnConfig.onFilter = (value, record) => {
              const recordDate = moment(record[key], "MM-DD-YYYY");

              if (record.group) {
                return true;
              }

              if (!recordDate.isValid()) {
                return false;
              }

              let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
              let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

              const formattedStartDate = startDate
                ? moment(startDate, "MM-DD-YYYY")
                : null;
              const formattedEndDate = endDate
                ? moment(endDate, "MM-DD-YYYY")
                : null;

              if (formattedStartDate && !formattedStartDate.isValid()) {
                console.error("Invalid start date:", startDate);
                return false;
              }

              if (formattedEndDate && !formattedEndDate.isValid()) {
                console.error("Invalid end date:", endDate);
                return false;
              }

              // Check if the record date is within the range
              const isDateInRange = recordDate.isBetween(
                formattedStartDate,
                formattedEndDate,
                null,
                "[]"
              );

              return isDateInRange;
            };

            columnConfig.sorter = (a, b) => {
              const isEmptyRow = (row) => {
                return !row.group && !row[key];
              };
              if (a.group || b.group) return 0;

              // Only sort if both rows are under the same parent group
              if (a.parentGroup === b.parentGroup) {
                const aEmpty = isEmptyRow(a);
                const bEmpty = isEmptyRow(b);

                // If both empty or both non-empty, sort normally
                if (aEmpty === bEmpty) {
                  if (a[key] && b[key]) {
                    const dateA = a[key] ? dayjs(a[key]) : null;
                    const dateB = b[key] ? dayjs(b[key]) : null;
                    return dateA.diff(dateB);
                  }
                  return 0;
                }
                // Empty rows always go to bottom
                if (aEmpty) return 1;
                if (bEmpty) return -1;
              }
              return 0;
            };
          }
          columnConfig.defaultSortOrder =
            value.sorting === "Descending"
              ? "descend"
              : value.sorting === "Ascending"
              ? "ascend"
              : undefined;
          columnConfig.render = (text) =>
            text ? dayjs(text).format("MM-DD-YYYY") : "";
        }
        return columnConfig;
      }),
  ];

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item, index) => ({
        key: item?.entryID || `entryID-${index}`,
        parentGroup: `${item?.parentGroup}`,
        itemName: `${item?.specTitle}`,
        submittalNumber: `${item?.specNumber}`,
        submittalRevison: `${item?.specRevision}`,
        ...Object.keys(alignedData).reduce((acc, column) => {
          acc[column] = item[column];
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, alignedData]);

  const onTabChange = (key) => {
    setSearchParam((prevParams) => {
      prevParams.set("tab", key);
      return prevParams;
    });

    // Update local state
    reportTabName = key;
    setSelectedTab(key);

    getData();
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    console.log(extra.currentDataSource, "Filtered Data:");
    setExportedData(extra.currentDataSource);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    const defaultPagination = { current: 1, pageSize: 10 };

    const updatedFilteredInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.filtering) {
          acc[key] = value.filtering.split(",").map((item) => item.trim());
        }
        return acc;
      },
      {}
    );

    const updatedSortedInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.sorting) {
          acc[key] = value.sorting;
        }
        return acc;
      },
      {}
    );

    // Apply filters to get filtered data
    const filteredData = dataSource.filter((record) => {
      return Object.entries(updatedFilteredInfo).every(([key, filters]) => {
        if (!filters || filters.length === 0) return true;
        return filters.includes(record[key]?.toString());
      });
    });

    setFilteredInfo(updatedFilteredInfo);
    setSortedInfo(updatedSortedInfo);

    handleChange(defaultPagination, updatedFilteredInfo, updatedSortedInfo, {
      action: "load",
      currentDataSource: filteredData,
    });
  }, [alignedData, dataSource]);

  // Search item names or submittal number
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = dataSource.filter(
    (item) =>
      item.specTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.submittalNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const isFilteredInfoEmpty = (filteredInfo) => {
    if (!filteredInfo || typeof filteredInfo !== "object") return true;
    const values = Object.values(filteredInfo);
    return values.length === 0 || values.every((value) => value == null);
  };

  return (
    <>
      <Container>
        {accessLevel > 1 && (
          <>
            <Breadcrumb
              className=" mt-4 "
              items={[
                {
                  title: (
                    <a
                      onClick={() => {}}
                      href={`/main/company/${company_id}/projects/${id}/project-custom-reports`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <div className=" d-flex justify-content-start align-items-center gap-1">
                        <LeftOutlined style={{ fontSize: "12px" }} />
                        Custom Reports
                      </div>
                    </a>
                  ),
                },
                {
                  title: <>{reportName || "Untitled"}</>,
                },
              ]}
            />
            <div className=" bg-white p-3 rounded-3 mt-4">
              <div className=" d-flex justify-content-between ">
                <div className=" d-flex flex-column">
                  <Text className={`${styles.header}`}>{reportPageTitle}</Text>
                  <p>{reportDescription}</p>
                </div>
                <div className="d-flex gap-2">
                  <Tooltip title="Share Report">
                    <Button
                      shape="circle"
                      disabled={isLoading}
                      onClick={() => {
                        setIsShareModalVisible(true);
                      }}
                      icon={
                        <ShareAltOutlined
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Clone Report">
                    <Button
                      shape="circle"
                      disabled={isLoading}
                      onClick={() => {
                        setIsCloneModalVisible(true);
                      }}
                      icon={
                        <CopyOutlined
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      }
                    />
                  </Tooltip>
                  <ExportReports
                    data={exportedData}
                    columns={columns}
                    accessLevel={accessLevel}
                    editedData={editedData}
                    isLoading={isLoading}
                    reportTitle={reportPageTitle}
                    projectName={projectName}
                    projAddress={projectAddress}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <Input
                    placeholder="Search Item Name or Submittal Number"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={isLoading}
                    style={{ width: "300px" }}
                    suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                  />
                </div>
              </div>
              <hr />
              <div className=" d-flex gap-1 w-100 mt-2 justify-content-between align-items-end">
                <div className=" w-100 d-flex flex-column gap-1">
                  <small>Grouped by: </small>
                  <div className=" d-flex">
                    {groupedColumns.length === 0 ? (
                      <Tag>None</Tag>
                    ) : (
                      <>
                        {groupedColumns.map((item) => (
                          <Tag key={item}>{item}</Tag>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className=" gap-2">
                  {email === reportOwner && (
                    <Button
                      type="primary"
                      onClick={() => {
                        const paramsObject = Object.fromEntries(
                          searchParams.entries()
                        );
                        navigate({
                          pathname: "edit",
                          search: new URLSearchParams(paramsObject).toString(),
                        });
                      }}
                    >
                      Edit Report
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row mt-4">
          {isLoading && !accessLevel ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {/* request failed */}
              {!accessLevel ? (
                <div className="row">
                  <div className="text-center">
                    <Text>Something went wrong, please try again later.</Text>
                  </div>
                </div>
              ) : accessLevel < 2 ? (
                // no permissions
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                // Main view
                <div>
                  <Tabs
                    onChange={onTabChange}
                    activeKey={selectedTab ? selectedTab : reportTabName}
                    tabBarExtraContent={
                      <Button
                        onClick={() => {
                          setFilteredInfo({});
                          handleChange(
                            { current: 1, pageSize: 10 },
                            {},
                            {},
                            {
                              action: "load",
                              currentDataSource: filteredData,
                            }
                          );
                        }}
                        disabled={isFilteredInfoEmpty(filteredInfo)}
                      >
                        Clear Filters
                      </Button>
                    }
                    className="rounded-3"
                    style={{ background: "white", padding: "5px" }}
                    items={reportTabs.map((item, index) => ({
                      label: `${item}`,
                      key: item,
                      children: (
                        <div>
                          <Table
                            columns={columns}
                            dataSource={filteredData}
                            scroll={{ x: 500, y: "70vh" }}
                            bordered
                            loading={
                              isLoading
                                ? { indicator: <LoadingComponent /> }
                                : false
                            }
                            pagination={{
                              position: ["bottomCenter"],
                              defaultPageSize: 20,
                            }}
                            onChange={handleChange}
                            size="small"
                          />
                        </div>
                      ),
                    }))}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <CloneReports
          isVisible={isCloneModalVisible}
          reportName={reportName}
          reportOwner={reportOwner}
          reportDescription={reportDescription}
          onCancel={() => setIsCloneModalVisible(false)}
          allReportNames={allReportNames}
          fetchCustomReports={getData}
        />
        <ShareReport
          isVisible={isShareModalVisible}
          reportName={reportName}
          contactsDictionary={contactsDictionary}
          onCancel={() => {
            setIsShareModalVisible(false);
          }}
          sharedItem={sharedListInfo}
          fetchCustomReports={getData}
        />
      </Container>
    </>
  );
}
