import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReportServices } from "apis/Services/ReportService";
import Container from "components/container";
import Text from "components/text";
import { Button, Tooltip, Table, Collapse, Input } from "antd";
import LoadingComponent from "components/loading-component";
import { Link } from "react-router-dom";
import ProjectReports from "pages/project-reports";
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteFilled,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";
import DeleteReports from "components/customReports/delete-reports";
import CloneReports from "components/customReports/clone-reports.js";
import ShareReport from "components/customReports/share-reports";

export default function ProjectCustomReport() {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [isSharedLoading, setisSharedLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();

  const [customReportsInfo, setCustomReportsInfo] = useState([]);
  const [sharedReportsInfo, setSharedReportsInfo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportTabs, setReportTabs] = useState([]);
  const [reportOwner, setReportOwner] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [contactsDictionary, setContactsDictionary] = useState({});
  const [sharedItem, setSharedItem] = useState([]);

  const navigate = useNavigate();
  const { Panel } = Collapse;

  const fetchCustomReports = async () => {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    ReportServices.readCustomReports(ReqObj)
      .then((res) => {
        setAccessLevel(parseInt(res.userInfo.accessLevel));
        setCustomReportsInfo(res.reportsInfo);
        setContactsDictionary(res.contactsDictionary);
        // console.log(res, "!!");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  };

  const fetchSharedCustomReports = async () => {
    setisSharedLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
    };
    ReportServices.readSharedCustomReport(ReqObj)
      .then((res) => {
        setSharedReportsInfo(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisSharedLoading(false));
  };

  useEffect(() => {
    fetchCustomReports();
    fetchSharedCustomReports();
  }, []);

  const handleDelete = (item) => {
    // console.log(item, "!?!?");
    setReportName(item?.report);
    setReportTabs(item?.reportTabs);
    setIsDeleteModalVisible(true);
  };

  const handleClone = (item) => {
    // console.log(item, "!?!?");
    setReportName(item?.report);
    setReportOwner(item?.reportOwnerEmail);
    setReportDescription(item?.reportDescription);
    setIsCloneModalVisible(true);
  };

  const handleShare = (item) => {
    // console.log(item, "!?!?");
    setReportName(item?.report);
    setSharedItem(item?.sharedListInfo);
    setIsShareModalVisible(true);
  };

  const dataSource = customReportsInfo?.map((report, index) => ({
    key: index.toString(),
    report: report.reportName,
    reportDescription: report.reportDescription,
    reportTabs: report.reportTabs,
    reportOwnerName: report.reportOwnerName,
    reportOwnerEmail: report.reportOwnerEmail,
    createdDate: report.createdDate
      ? moment(report.createdDate).format("MM/DD/YY")
      : "",
    modifiedDate: report.modifiedDate
      ? moment(report.modifiedDate).format("MM/DD/YY")
      : "",
    sharedListInfo: report.sharedListInfo,
  }));

  const sharedDataSource = sharedReportsInfo?.map((report, index) => ({
    key: index.toString(),
    report: report.reportName,
    reportDescription: report.reportDescription,
    reportTabs: report.reportTabs,
    reportOwnerName: report.reportOwnerName,
    reportOwnerEmail: report.reportOwnerEmail,
    createdDate: report.createdDate
      ? moment(report.createdDate).format("MM/DD/YY")
      : "",
    modifiedDate: report.modifiedDate
      ? moment(report.modifiedDate).format("MM/DD/YY")
      : "",
  }));

  // Function to get columns dynamically based on table type
  const getColumns = (type) => {
    return [
      {
        title: "Report Name",
        dataIndex: "report",
        key: "report",
        render: (text, record) => (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title="View">
              <Link
                to={`${record.report}?owner=${encodeURIComponent(
                  record.reportOwnerEmail
                )}&tab=${encodeURIComponent(record.reportTabs[0])}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {text}
              </Link>
            </Tooltip>
          </div>
        ),
      },
      {
        title: "Description",
        dataIndex: "reportDescription",
        key: "reportDescription",
      },
      {
        title: "Owner",
        dataIndex: "reportOwnerName",
        key: "reportOwnerName",
        ...(type === "shared" && {
          sorter: (a, b) => a.reportOwnerName.localeCompare(b.reportOwnerName),
          filters: [
            ...Array.from(
              new Set(sharedDataSource.map((item) => item.reportOwnerName))
            ).map((owner) => ({ text: owner, value: owner })),
          ],
          onFilter: (value, record) => record.reportOwnerName === value,
        }),
      },
      {
        title: "Date Created",
        dataIndex: "createdDate",
        key: "createdDate",
        sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
      },
      {
        title: "Last Modified",
        dataIndex: "modifiedDate",
        key: "modifiedDate",
        sorter: (a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate),
      },
      {
        title: "Actions",
        align: "center",
        key: "actions",
        render: (_, record) => {
          if (type === "shared") {
            return (
              <Tooltip title="Clone">
                <CopyOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClone(record)}
                />
              </Tooltip>
            );
          }
          return (
            <div className=" w-100 d-flex justify-content-around gap-2">
              <Tooltip title="Share">
                <ShareAltOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShare(record)}
                />
              </Tooltip>
              <Tooltip title="Clone">
                <CopyOutlined
                  style={{
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClone(record)}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteFilled
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(record)}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ];
  };

  // Filter reports based on the search query
  const filteredDataSource = dataSource.filter((report) =>
    report.report.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredSharedDataSource = sharedDataSource.filter((report) =>
    report.report.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const allReportNames = [
    ...customReportsInfo.map((report) => report.reportName.toLowerCase()),
  ];

  return (
    <>
      <ProjectReports pageType="project-custom-reports" />

      <Container className="mt-4">
        {isLoading && (
          <>
            <div className="row d-flex justify-content-center">
              <LoadingComponent />
            </div>
          </>
        )}

        {!isLoading && accessLevel === "0" && (
          <div className="row">
            <div className="text-center">
              <Text>
                You do not have the necessary permissions to access this page.
              </Text>
            </div>
          </div>
        )}
        {!isLoading && accessLevel > 0 && (
          <div className=" bg-white p-3 rounded-2">
            <div className=" d-flex justify-content-between my-2 mb-4">
              <div className=" d-flex flex-column">
                <Input
                  placeholder="Search by report name"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ width: "200px" }}
                  suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                />
              </div>
              <Button
                type="primary"
                onClick={() => {
                  navigate({
                    pathname: "create-new-report",
                  });
                }}
              >
                Create Report
              </Button>
            </div>
            {/* My Reports Collapse */}
            <Collapse
              defaultActiveKey={["1"]}
              className="mb-4"
              ghost
              expandIconPosition={"start"}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{ background: "#F8F8F8" }}
            >
              <Panel
                header={`My Reports (${filteredDataSource.length})`}
                key="1"
              >
                <Table
                  dataSource={filteredDataSource}
                  columns={getColumns("default")}
                  pagination={false}
                  bordered
                  loading={
                    isLoading ? { indicator: <LoadingComponent /> } : false
                  }
                />
              </Panel>
            </Collapse>
            {/* Shared Reports Collapse */}
            <Collapse
              defaultActiveKey={[""]}
              className="mb-4"
              ghost
              expandIconPosition={"start"}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{ background: "#F8F8F8" }}
            >
              <Panel
                header={`Shared Reports (${filteredSharedDataSource.length})`}
                key="1"
              >
                <Table
                  dataSource={filteredSharedDataSource}
                  columns={getColumns("shared")}
                  pagination={false}
                  bordered
                  loading={
                    isSharedLoading
                      ? { indicator: <LoadingComponent /> }
                      : false
                  }
                />
              </Panel>
            </Collapse>
          </div>
        )}
        <DeleteReports
          isVisible={isDeleteModalVisible}
          reportName={reportName}
          reportTabs={reportTabs}
          onCancel={() => setIsDeleteModalVisible(false)}
          fetchCustomReports={fetchCustomReports}
          fetchSharedCustomReports={fetchSharedCustomReports}
        />
        <CloneReports
          isVisible={isCloneModalVisible}
          reportName={reportName}
          reportOwner={reportOwner}
          reportDescription={reportDescription}
          onCancel={() => setIsCloneModalVisible(false)}
          fetchCustomReports={fetchCustomReports}
          fetchSharedCustomReports={fetchSharedCustomReports}
          allReportNames={allReportNames}
        />
        <ShareReport
          isVisible={isShareModalVisible}
          reportName={reportName}
          contactsDictionary={contactsDictionary}
          onCancel={() => {
            setSharedItem([]);
            setIsShareModalVisible(false);
          }}
          fetchCustomReports={fetchCustomReports}
          fetchSharedCustomReports={fetchSharedCustomReports}
          sharedItem={sharedItem}
        />
      </Container>
    </>
  );
}
