import { React, useState, useEffect } from "react";
import LoadingComponent from "components/loading-component";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Radio, Table, Checkbox, Modal, Tooltip, Alert } from "antd";
import { SettingsService } from "apis/Services/SettingsService";
import {
  SettingOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import lateIcon from "assets/images/late-icon.svg";
import pendingIcon from "assets/images/pending-icon.svg";

import warningStyleImage00 from "assets/images/warning-style-0.png";
import warningStyleImage01 from "assets/images/warning-style-1.png";
import { useDispatch } from "react-redux";
import { reloadProjects } from "redux/project-reducer";
import { ProjectServices } from "apis/Services/ProjectService";

function Settings({ projectsAccessLevelValue }) {
  const { company_id, id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [data, setData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});
  const [isManagerChecked, setIsManagerChecked] = useState(false);
  const [isContractorChecked, setIsContractorChecked] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const [warningStyleValue, setWarningStyleValue] = useState("0");
  const [nestSubmittals, setNestSubmittals] = useState("0");
  const [overwriteCalculations, setOverwriteCalculations] = useState("0");
  const [shouldRecalculate, setShouldRecalulate] = useState(false);
  const [
    hideEmptyDivisionsAndSubdivisions,
    setHideEmptyDivisionsAndSubdivisions,
  ] = useState("0");

  const dispatch = useDispatch();

  function getSettingsData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
    };
    SettingsService.getProcurementLogSettings(ReqObj)
      .then((res) => {
        setData(res);
        setWarningStyleValue(res.warningStyle);
        setHideEmptyDivisionsAndSubdivisions(
          res.hideEmptyDivisionsAndSubdivisions
        );
        setNestSubmittals(res.nestSubmittals);
        setOverwriteCalculations(res.overwriteCalculations);
        const initialCheckedValues = {};
        Object.keys(res).forEach((key) => {
          initialCheckedValues[key] = {
            manager: res[key].manager === "1",
            contractor: res[key].contractor === "1",
          };
        });
        delete initialCheckedValues.warningStyle;
        delete initialCheckedValues.hideEmptyDivisionsAndSubdivisions;
        delete initialCheckedValues.nestSubmittals;
        delete initialCheckedValues.overwriteCalculations;
        setCheckedValues(initialCheckedValues);
      })
      .catch((err) => {
        setIsFirstTime(true);
        setHideEmptyDivisionsAndSubdivisions("0");
        setNestSubmittals("0");
        setOverwriteCalculations("0");
        setData({
          contractIssuedReleaseNotification: {
            manager: "0",
            contractor: "0",
          },
          submittalIssuedNotification: {
            manager: "0",
            contractor: "0",
          },
          submittalReturnedNotification: {
            manager: "0",
            contractor: "0",
          },
          dateRequiredOnSiteNotification: {
            manager: "0",
            contractor: "0",
          },
        });
        setCheckedValues(data);
        setWarningStyleValue("0");
      })
      .finally(() => {
        setisLoading(false);
      });
  }

  // const updateProjectsData = (
  //   // company_id,
  //   // code,
  //   // loginEmail,
  //   approvalTime,
  //   reviewTime
  // ) => {
  //   const updateBackEndData = {
  //     company: company_id,
  //     id: id,
  //     code,
  //     loginEmail,
  //     approvalTime: approvalTime,
  //     reviewTime: reviewTime,
  //   };

  //   return ProjectServices.getExportData(updateBackEndData);
  // };

  function updateSettings() {
    setIsSaving(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    const updatedData = {};
    Object.keys(checkedValues).forEach((key) => {
      updatedData[key] = {
        manager: checkedValues[key].manager ? "1" : "0",
        contractor: checkedValues[key].contractor ? "1" : "0",
      };
    });
    updatedData.warningStyle = warningStyleValue;
    updatedData.hideEmptyDivisionsAndSubdivisions =
      hideEmptyDivisionsAndSubdivisions;
    updatedData.nestSubmittals = nestSubmittals;
    updatedData.overwriteCalculations = overwriteCalculations;

    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
    };

    SettingsService.updateProcurementLogSettings(ReqObj, updatedData)
      .then((res) => {
        toast.success("Your settings have been successfully saved.");
        // updateProjectsData();
        dispatch(reloadProjects());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsSaving(false);
      });
  }

  function createSettings() {
    setIsSaving(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    const updatedData = {
      contractIssuedReleaseNotification: {
        manager: "0",
        contractor: "0",
      },
      submittalIssuedNotification: {
        manager: "0",
        contractor: "0",
      },
      submittalReturnedNotification: {
        manager: "0",
        contractor: "0",
      },
      dateRequiredOnSiteNotification: {
        manager: "0",
        contractor: "0",
      },
    };
    Object.keys(checkedValues).forEach((key) => {
      updatedData[key] = {
        manager: checkedValues[key].manager ? "1" : "0",
        contractor: checkedValues[key].contractor ? "1" : "0",
      };
    });
    updatedData.warningStyle = warningStyleValue;
    updatedData.hideEmptyDivisionsAndSubdivisions =
      hideEmptyDivisionsAndSubdivisions;
    updatedData.nestSubmittals = nestSubmittals;
    updatedData.overwriteCalculations = overwriteCalculations;

    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
    };

    setIsFirstTime(false);

    SettingsService.setProcurementLogSettings(ReqObj, updatedData)
      .then((res) => {
        toast.success("Your settings have been successfully saved.");
        dispatch(reloadProjects());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setIsModalOpen(false);
        setIsSaving(false);
      });
  }

  const handleCheckboxChange = (name, role, checked) => {
    setCheckedValues((prevValues) => ({
      ...prevValues,
      [name]: {
        ...prevValues[name],
        [role]: checked,
      },
    }));
  };

  const handleRadioChange = (e) => {
    setWarningStyleValue(e.target.value);
    setIsShowAlert(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
    getSettingsData();
  };
  const handleOk = () => {
    // console.log("Updated checkbox values:", checkedValues);
    if (isFirstTime) {
      createSettings();
    } else {
      updateSettings();
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsFirstTime(false);
  };
  const handleManagerColumnClick = () => {
    const updatedCheckedValues = {};
    dataSource.forEach((item) => {
      updatedCheckedValues[item.name] = {
        ...checkedValues[item.name],
        manager: !isManagerChecked ? true : false,
      };
    });
    setCheckedValues(updatedCheckedValues);
    setIsManagerChecked(!isManagerChecked);
  };

  const handleContractorColumnClick = () => {
    const updatedCheckedValues = {};
    dataSource.forEach((item) => {
      updatedCheckedValues[item.name] = {
        ...checkedValues[item.name],
        contractor: !isContractorChecked ? true : false,
      };
    });
    setCheckedValues(updatedCheckedValues);
    setIsContractorChecked(!isContractorChecked);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <div className=" d-flex gap-2  align-items-center">
          {text === "contractIssuedReleaseNotification"
            ? "Contract Issued Release Notification"
            : text === "submittalIssuedNotification"
            ? "Submittal Issued Notification"
            : text === "submittalReturnedNotification"
            ? "Submittal Returned Notification"
            : text === "dateRequiredOnSiteNotification"
            ? "Date Required on Site Notification"
            : text}
          <Tooltip
            title={
              text === "contractIssuedReleaseNotification"
                ? "Get notified when Contract Issued Release is approaching or overdue"
                : text === "submittalIssuedNotification"
                ? "Get notified when Submittal Issued is approaching or overdue"
                : text === "submittalReturnedNotification"
                ? "Get notified when Submittal Returned is approaching or overdue"
                : text === "dateRequiredOnSiteNotification"
                ? "Get notified when Date Required on Site is approaching or overdue"
                : ""
            }
            placement="top"
          >
            <InfoCircleOutlined style={{ color: "#bdbdbd" }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (checked, record) => (
        <Checkbox
          checked={checkedValues[record.name]?.manager}
          onChange={(e) =>
            handleCheckboxChange(record.name, "manager", e.target.checked)
          }
          disabled={isSaving}
        />
      ),
      align: "center",
      onHeaderCell: () => ({
        style: { cursor: "pointer" },
        onClick: () => handleManagerColumnClick(),
      }),
    },
    {
      title: "Contractor",
      dataIndex: "contractor",
      key: "contractor",
      render: (checked, record) => (
        <Checkbox
          checked={checkedValues[record.name]?.contractor}
          onChange={(e) =>
            handleCheckboxChange(record.name, "contractor", e.target.checked)
          }
          disabled={isSaving}
        />
      ),
      align: "center",
      onHeaderCell: () => ({
        style: { cursor: "pointer" },
        onClick: () => handleContractorColumnClick(),
      }),
    },
  ];

  //removing warningstyle from the table
  delete data.warningStyle;
  delete data.viewsInfo;
  delete data.hideEmptyDivisionsAndSubdivisions;
  delete data.nestSubmittals;
  delete data.overwriteCalculations;

  const dataSource = Object.keys(data).map((key) => ({
    key,
    name: key,
    manager: data[key].manager === "1",
    contractor: data[key].contractor === "1",
  }));
  return (
    <>
      <Button
        type="text"
        disabled={projectsAccessLevelValue < 3}
        onClick={projectsAccessLevelValue > 2 ? showModal : null}
        className="d-flex justify-content-left p-2 align-items-center"
      >
        <SettingOutlined style={{ fontSize: "18px" }} />
      </Button>
      <Modal
        open={isModalOpen}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isSaving ? (
              <LoadingOutlined style={{ fontSize: "15px" }} />
            ) : (
              "Save"
            )}
          </div>
        }
        okButtonProps={{ disabled: isSaving }}
        cancelButtonProps={{ disabled: isSaving }}
      >
        {!isLoading ? (
          <>
            <h4 className="pt-3">Notification Settings</h4>
            <Alert
              message="Selected items nearing due dates or overdue trigger automated
                emails. Items nearing due date receive emails 1, 3, 7, and 14
                days before, while overdue items receive daily emails.
                "
              // showIcon
              type="info"
              style={{
                fontSize: "13px",
                marginBottom: "10px",
                marginTop: "5px",
              }}
            />
            <p
              className=" opacity-75 mt-2"
              style={{
                fontSize: "13px",
              }}
            >
              Item Managers and Contractors can be assigned and viewed using the
              item's side bar.
            </p>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              bordered
            />

            <div className=" d-flex flex-column gap-1">
              <Checkbox
                className=" mt-4"
                checked={hideEmptyDivisionsAndSubdivisions === "1"}
                onChange={(e) =>
                  setHideEmptyDivisionsAndSubdivisions(
                    e.target.checked ? "1" : "0"
                  )
                }
                disabled={isSaving}
              >
                Hide non-utilized spec sections and divisions
              </Checkbox>

              <Checkbox
                className=" m-0"
                checked={nestSubmittals === "1"}
                onChange={(e) =>
                  setNestSubmittals(e.target.checked ? "1" : "0")
                }
                disabled={isSaving}
              >
                Nest items with same submittal number and revision into a
                parent-child sequence
              </Checkbox>

              {/* <Checkbox
                className=" m-0"
                checked={overwriteCalculations === "1"}
                onChange={(e) => {
                  setOverwriteCalculations(e.target.checked ? "1" : "0");
                  setShouldRecalulate(true);
                }}
                disabled={isSaving}
              >
                Match Calculations
              </Checkbox> */}
            </div>

            <h4 className=" pt-4">Warning Style</h4>
            <div className=" d-flex flex-column gap-2">
              <div className="d-flex gap-2 mt-2">
                <img src={pendingIcon} alt="pending" width={16} />
                <p className="m-0 p-0">
                  This warning icon signifies that the item is due in the next 7
                  days.
                </p>
                <Tooltip title="This warning icon signifies that the item's deadline or due date is approaching within the next 7 days. It serves as a reminder to take action or prioritize tasks associated with the item.">
                  <InfoCircleOutlined
                    style={{ color: "#bdbdbd", alignSelf: "center" }}
                  />
                </Tooltip>
              </div>
              <div className="d-flex gap-2">
                <img src={lateIcon} alt="late" width={16} />
                <p className="m-0 p-0">
                  This warning icon signifies that the item’s due date is today
                  or has passed.
                </p>
                <Tooltip title="This warning icon signals that the Item’s due date is today or has passed. It suggests that the task or action associated with the item is overdue and requires immediate attention to avoid potential consequences or delays.">
                  <InfoCircleOutlined
                    style={{ color: "#bdbdbd", alignSelf: "center" }}
                  />
                </Tooltip>
              </div>
            </div>

            {/* <div
              className={`d-flex justify-content-center align-items-center mt-3 ${
                isShowAlert ? "d-display" : "d-none"
              } `}
            >
              <Alert
                message="Changes to the warning style will take effect after reloading the page."
                showIcon
                type="warning"
                style={{ fontSize: "14px" }}
              />
            </div> */}
            <div className=" d-flex justify-content-center align-items-center">
              <Radio.Group
                onChange={handleRadioChange}
                value={warningStyleValue}
                style={{ display: "flex" }}
              >
                <Radio
                  value="0"
                  disabled={isSaving}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                >
                  <div className=" d-flex flex-column justify-content-center align-items-center ">
                    <img src={warningStyleImage00} width={150} alt="Default" />
                    <span>Default</span>
                  </div>
                </Radio>
                <Radio
                  value="1"
                  disabled={isSaving}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                >
                  <div className=" d-flex flex-column justify-content-center align-items-center">
                    <img src={warningStyleImage01} width={150} alt="Colored" />
                    <span>Colored</span>
                  </div>
                </Radio>
              </Radio.Group>
            </div>
          </>
        ) : (
          <>
            <h4 className="py-3">Notification Settings</h4>
            <LoadingComponent />
          </>
        )}
      </Modal>
    </>
  );
}

export default Settings;
