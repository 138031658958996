import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import GrowingAlert from "components/growing-alert";
import { Badge, Progress } from "antd";
import { ProjectServices } from "apis/Services/ProjectService";
import Text from "components/text";
import styles from "./table.module.css";
import { useEffect } from "react";

const ProjectRow = ({ project = {} }) => {
  const navigate = useNavigate();
  const { company_id } = useParams();
  const projectLink = `/main/company/${company_id}/projects/${project["Project ID"]}`;

  return (
    <tr
      className={`${styles.projectRow} pointer`}
      onClick={() => navigate(projectLink)}
    >
      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          {project["Project Number"]}
        </Link>
      </td>
      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          {project["Project Name"]}
        </Link>
      </td>
      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          {project["Type"]}
        </Link>
      </td>
      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          {project["actualStartDate"]}
        </Link>
      </td>
      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          {project["completionDate"]}
        </Link>
      </td>
      <td className={`${styles.bigCells}`}>
        <Progress
          className={`${styles.progressBar}`}
          percent={project?.["Progress"]}
          // format={() => "45 items late"}
          format={() => `${project?.["Progress"].toFixed(2)}% Completed`}
        />
      </td>

      <td className={`${styles.col}`}>
        <Link to={projectLink} className={`${styles.rowText}`}>
          <Badge
            status={
              project["RegistrationStatus"] === "Active" ? "success" : "error"
            }
            text={project["RegistrationStatus"]}
          />
        </Link>
      </td>

      <td className={`${styles.bigCells}`}>
        <div className="d-flex gap-2">
          <GrowingAlert number={project.DraftEntries} type="draft" />
          <GrowingAlert
            number={project.ApproachingDealineEntries}
            type="pending"
          />
          <GrowingAlert number={project.OverdueEntries} type="overdue" />
          <GrowingAlert number={project.CompletedEntries} type="completed" />
        </div>
      </td>
    </tr>
  );
};

export default ProjectRow;
