import { Input, Modal, Table } from "antd";
import { ReportServices } from "apis/Services/ReportService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";

function ShareReport({
  isVisible,
  reportName,
  contactsDictionary,
  onCancel,
  fetchCustomReports,
  fetchSharedCustomReports,
  sharedItem,
}) {
  const { company_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sharedUserEmails, setSharedUserEmails] = useState([]);
  const [deleteSharedUserEmails, setDeleteSharedUserEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const initialSelected = sharedItem?.map((item) => item.sharedEmail) || [];
    setSelectedEmails(initialSelected);
    setSharedUserEmails(initialSelected);
  }, [sharedItem]);

  const contactsData = Object.keys(contactsDictionary || {}).map((email) => ({
    key: email,
    name: `${contactsDictionary[email].firstName} ${contactsDictionary[email].lastName}`,
    email,
    companyName: contactsDictionary[email].companyName,
  }));

  const onSelectChange = (selectedRowKeys) => {
    const newlyAddedEmails = selectedRowKeys.filter(
      (email) => !sharedItem.some((item) => item.sharedEmail === email)
    );
    const removedEmails = sharedItem
      .map((item) => item.sharedEmail)
      .filter((email) => !selectedRowKeys.includes(email));

    setSharedUserEmails(newlyAddedEmails);
    setDeleteSharedUserEmails(removedEmails);

    setSelectedEmails(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedEmails,
    onChange: onSelectChange,
  };

  const handleRowClick = (email) => {
    const updatedSelection = selectedEmails.includes(email)
      ? selectedEmails.filter((selectedEmail) => selectedEmail !== email)
      : [...selectedEmails, email];

    onSelectChange(updatedSelection);
  };

  function shareReport(reportName, sharedUserEmails) {
    setIsLoading(true);
    const toastId = toast.loading("Applying Changes...");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName,
      sharedUserEmails: sharedUserEmails,
    };

    ReportServices.shareCustomReport(ReqObj)
      .then((res) => {
        toast.success("Changes applied successfully", { id: toastId });
        fetchCustomReports();
        // fetchSharedCustomReports();
      })
      .catch((err) => {
        console.log("Error sharing the report:", err);
        toast.error("Something went wrong, please try again.", {
          id: toastId,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setSearchQuery("");
        onCancel();
      });
  }

  function deleteShare(reportName, deleteSharedUserEmails) {
    const toastId = toast.loading("Saving Changes...");
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName,
      sharedUserEmails: deleteSharedUserEmails,
    };

    ReportServices.deleteSharedCustomReport(ReqObj)
      .then((res) => {
        toast.success("Report shared successfully", { id: toastId });
        fetchCustomReports();
        // fetchSharedCustomReports();
      })
      .catch((err) => {
        console.log("Error deleting shared users from the report:", err);
        toast.error("Something went wrong, please try again.", {
          id: toastId,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setSearchQuery("");
        onCancel();
      });
  }

  const filteredDataSource = contactsData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.companyName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      title="Share Report"
      open={isVisible}
      onCancel={onCancel}
      centered
      width={900}
      height={700}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="share"
          type="primary"
          disabled={
            sharedUserEmails.length === 0 && deleteSharedUserEmails.length === 0
          }
          onClick={() => {
            if (sharedUserEmails.length > 0) {
              shareReport(reportName, sharedUserEmails);
            }
            if (deleteSharedUserEmails.length > 0) {
              deleteShare(reportName, deleteSharedUserEmails);
            }
          }}
          loading={isLoading}
        >
          Apply Changes
        </Button>,
      ]}
    >
      <div className=" d-flex justify-content-between align-items-center mb-2">
        <p>
          Select users to share the report: <strong>{reportName}</strong>
        </p>
        <Input
          placeholder="Search Users"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          style={{ width: "200px" }}
          suffix={<SearchOutlined style={{ opacity: "50%" }} />}
        />
      </div>
      <Table
        rowSelection={rowSelection}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 210,
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          { title: "Email", dataIndex: "email", key: "email" },
          {
            title: "Company",
            dataIndex: "companyName",
            key: "companyName",
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          },
        ]}
        dataSource={filteredDataSource}
        pagination={false}
        scroll={{ y: 400 }}
        onRow={(record) => ({
          style: { cursor: "pointer" },
          onClick: () => handleRowClick(record.email),
        })}
      />
    </Modal>
  );
}

export default ShareReport;
