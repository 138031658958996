import { Modal } from "antd";
import { ReportServices } from "apis/Services/ReportService";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";

function DeleteReports({
  reportName,
  reportTabs,
  isVisible,
  onCancel,
  fetchCustomReports,
  fetchSharedCustomReports,
}) {
  const { company_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  function deleteReport(reportName, reportTabs) {
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName,
      reportTabNames: reportTabs,
    };

    ReportServices.deleteCustomReport(ReqObj)
      .then((res) => {
        fetchCustomReports();
        fetchSharedCustomReports();
      })
      .catch((err) => {
        console.log("Error deleting report:", err);
      })
      .finally(() => {
        setIsLoading(false);
        onCancel();
      });
  }

  return (
    <Modal
      title={`Delete Report`}
      open={isVisible}
      onCancel={onCancel}
      centered
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={() => deleteReport(reportName, reportTabs)}
          loading={isLoading}
        >
          Delete
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to delete the report <strong>{reportName}</strong>
        ? This action cannot be undone.
      </p>
    </Modal>
  );
}

export default DeleteReports;
