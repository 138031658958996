import { Button, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CalendarImg from "assets/images/calendar-icon.svg";
import Container from "components/container";
import ContentContainer from "components/content-container";
import InputComponent from "components/input-component";
import LoadingComponent from "components/loading-component";
import { ProjectServices } from "apis/Services/ProjectService";
import ProjectTable from "components/projects-table";
import RadioGroupComponent from "components/radio-buttons-group";
import SearchIcon from "assets/images/search-icon.svg";
import SeparateLine from "components/separate-line";
import SideMenu from "components/side-menu";
import Text from "components/text";
import moment from "moment/moment";
import { projectDetailsToDefault } from "redux/project-reducer";
import styles from "./project.module.css";
import { useEffect } from "react";
import { FilterOutlined, FilterFilled } from "@ant-design/icons";

const Projects = () => {
  const { company_id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [ProjectsContent, setProjectsContent] = useState([]);
  const [SideNavShown, setSideNavShown] = useState(false);
  const { companiesId } = useSelector((state) => state.Auth);

  const handleOpenMenu = () => {
    setSideNavShown(true);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  useEffect(() => {
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    setisLoading(true);
    ProjectServices.getProjects({ company_id, code, loginEmail: email })
      .then((res) => {
        setProjectsContent(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setisLoading(false));
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(projectDetailsToDefault());
  }, []);

  const [progressFilter, setProgressFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [projectName, setProjectName] = useState("");
  const [tableData, settableData] = useState([]);

  const getFilteredProjects = () => {
    let filteredProjects = ProjectsContent?.content;

    if (progressFilter && typeFilter && statusFilter) {
      filteredProjects = filteredProjects?.filter(
        (project) =>
          project.Type === typeFilter &&
          project.RegistrationStatus === statusFilter &&
          isProgressInRange(project.Progress, progressFilter)
      );
    } else if (progressFilter) {
      filteredProjects = filteredProjects?.filter((project) =>
        isProgressInRange(project.Progress, progressFilter)
      );
    } else if (typeFilter) {
      filteredProjects = filteredProjects?.filter(
        (project) => project.Type === typeFilter
      );
    } else if (statusFilter) {
      filteredProjects = filteredProjects?.filter(
        (project) => project.RegistrationStatus === statusFilter
      );
    }

    const projectNameFilter = projectName?.toLowerCase();
    filteredProjects = filteredProjects?.filter(
      (project) =>
        project["Project Name"].toLowerCase().includes(projectNameFilter) ||
        project["Project Number"]
          .replace(/\s/g, "")
          .includes(projectNameFilter) ||
        project["Type"].toLowerCase().includes(projectNameFilter) ||
        project["Project Number"].includes(projectNameFilter)
    );
    settableData(filteredProjects);
    // return filteredProjects;
  };
  useEffect(() => {
    if (!tableData?.length) {
      getFilteredProjects();
    }
  }, [ProjectsContent]);

  const isProgressInRange = (projectProgress, filterValue) => {
    if (filterValue === "100% Complete") {
      return projectProgress === "100% Complete";
    } else if (filterValue === "less than 50% Complete") {
      return (
        projectProgress !== "100% Complete" &&
        parseFloat(projectProgress) > 0 &&
        parseFloat(projectProgress) < 50
      );
    } else if (filterValue === "more than 50% Complete") {
      return (
        projectProgress !== "100% Complete" && parseFloat(projectProgress) >= 50
      );
    } else if (filterValue === "0% Complete") {
      return projectProgress == "0";
    } else {
      return false;
    }
  };

  const resetFilters = () => {
    setProgressFilter(null);
    setTypeFilter(null);
    setStatusFilter(null);
    setProjectName("");
  };

  useEffect(() => {
    getFilteredProjects();
  }, [progressFilter, typeFilter, statusFilter, projectName]);
  const [selectedCompanyIdIndex, setSelectedCompanyIdIndex] = useState(
    Number(localStorage.getItem("company_id"))
  );

  var sanitizedObjectString = localStorage.getItem("companies_object");
  var companiesIdsObject = JSON.parse(sanitizedObjectString);

  useEffect(() => {
    // console.log(companiesIdsObject, "companiesIdsObject");
  }, []);

  const selectOptions = Object.keys(companiesIdsObject).map((name) => ({
    value: companiesIdsObject[name],
    label: name,
  }));

  useEffect(() => {
    // console.log(selectOptions, "selectedoptionsselectedoptionsselectedoptions");
    // console.log(
    //   selectedCompanyIdIndex,
    //   "selectedoptionsselectedoptionsselectedoptions"
    // );
    if (selectedCompanyIdIndex !== Number(localStorage.getItem("company_id"))) {
      // console.log(selectedCompanyIdIndex, "selectedCompanyIdIndex");

      // const selectedCompanyId = companiesIdsObject[selectedCompanyIdIndex];

      navigate(`/main/company/${selectedCompanyIdIndex}/projects`);
      localStorage.setItem("company_id", selectedCompanyIdIndex);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }, [selectedCompanyIdIndex]);

  const handleCompanySelect = (selectedOption) => {
    setSelectedCompanyIdIndex(selectedOption);
  };
  // console.log(tableData, "tableData");

  return (
    <>
      <div className={`d-flex flex-column nav-rest`}>
        <div className="d-flex justify-content-center">
          <SeparateLine />
        </div>
        <div className={` d-flex justify-content-between py-3 px-3`}>
          <div className="d-flex gap-2 align-items-center flex-wrap flex-md-nowrap">
            <Text className={`main-nav-title`}>Projects</Text>
            {/* <Text className={`main-nav-desc d-md-inline d-none`}>
              Find below all saved projects and you can create new project.
            </Text> */}
          </div>

          {/* <ButtonComponent
            title={"Add Project"}
            className="w-auto px-5"
            onClickAction={() => navigate("/main/company/50/create-project")}
          /> */}
        </div>
      </div>

      <SideMenu show={SideNavShown} setShow={setSideNavShown}>
        <RadioGroupComponent
          header="Project Progress"
          // data={ProgressArr}
          data={ProjectsContent?.content}
          valueKey={"Progress"}
          labelKey={"Progress"}
          className="my-3"
          selected={progressFilter}
          setSelected={setProgressFilter}
        />
        <SeparateLine />

        <RadioGroupComponent
          header="Project Types"
          // data={TypesArr}
          data={ProjectsContent?.content}
          valueKey={"Type"}
          labelKey={"Type"}
          className="my-3"
          selected={typeFilter}
          setSelected={setTypeFilter}
        />
        <SeparateLine />

        <RadioGroupComponent
          header="Registeration Status"
          // data={ProgressArr}
          data={ProjectsContent?.content}
          valueKey={"RegistrationStatus"}
          labelKey={"RegistrationStatus"}
          className="my-3"
          selected={statusFilter}
          setSelected={setStatusFilter}
        />

        <Button className="mt-5" onClick={resetFilters}>
          Reset
        </Button>
      </SideMenu>

      <Container className="my-4">
        <ContentContainer className="py-3">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center px-2 py-2">
              <InputComponent
                className="w-auto"
                placeholder="Search"
                Icon={<img src={SearchIcon} alt="search icon" />}
                onChange={(event) => setProjectName(event)}
              />
              <div className={`d-flex align-items-center gap-3 px-5`}>
                <div>
                  <Select
                    style={{ width: "200px" }}
                    options={selectOptions}
                    value={selectedCompanyIdIndex}
                    onChange={handleCompanySelect}
                    placeholder={"select a company"}
                  />
                </div>
                <div
                  className={`${styles.iconsContainer} px-3 ${
                    (progressFilter !== null && progressFilter !== "") ||
                    (typeFilter !== null && typeFilter !== "")
                      ? `${styles.filtersApplied}`
                      : ""
                  }`}
                  onClick={handleOpenMenu}
                >
                  {/* <img
                    src={filterIcon}
                    alt="filter"
                    className={`pointer ${styles.invertSvg}`}
                  /> */}

                  {(progressFilter !== null && progressFilter !== "") ||
                  (typeFilter !== null && typeFilter !== "") ? (
                    <FilterFilled
                      style={{
                        fontSize: 20,
                        color: "black",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FilterOutlined
                      style={{
                        fontSize: 20,
                        color: "black",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
                <DateIndicator />
              </div>
            </div>
            <SeparateLine />

            {isLoading ? (
              <LoadingComponent />
            ) : (
              <ProjectTable data={tableData} />
            )}
          </div>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Projects;

const DateIndicator = () => {
  return (
    <div
      className={`${styles.iconsContainer} d-flex align-items-center gap-2 px-3`}
    >
      <img src={CalendarImg} alt="calendar-icon" />
      <Text className={`${styles.date}`}>{moment().format("DD MMMM")}</Text>
    </div>
  );
};
