import { DatePicker } from "antd";
import { useState } from "react";
import { Button } from "antd";

const DateRangeFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  columnKey,
  setFilteredInfo,
  setTabsData,
  selectedTab,
  reportTabName,
  setUnsavedTabs,
}) => {
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);

  const handleConfirm = () => {
    if (localStartDate && localEndDate) {
      const formattedStartDate = localStartDate.format("MM-DD-YYYY");
      const formattedEndDate = localEndDate.format("MM-DD-YYYY");

      setSelectedKeys([formattedStartDate, formattedEndDate]);

      setFilteredInfo((prev) => ({
        ...prev,
        [columnKey]: [formattedStartDate, formattedEndDate],
      }));

      const currentTabKey = selectedTab || reportTabName;
      setTabsData((prev) => ({
        ...prev,
        [currentTabKey]: {
          ...prev[currentTabKey],
          alignedData: {
            ...prev[currentTabKey]?.alignedData,
            [columnKey]: {
              ...prev[currentTabKey]?.alignedData[columnKey],
              filtering: `${formattedStartDate},${formattedEndDate}`,
            },
          },
        },
      }));

      setUnsavedTabs((prev) => ({
        ...prev,
        [selectedTab]: true,
      }));

      confirm();
    }
  };

  const handleReset = () => {
    setLocalStartDate(null);
    setLocalEndDate(null);
    setSelectedKeys([]);

    const currentTabKey = selectedTab || reportTabName;
    setTabsData((prev) => ({
      ...prev,
      [currentTabKey]: {
        ...prev[currentTabKey],
        alignedData: {
          ...prev[currentTabKey]?.alignedData,
          [columnKey]: {
            ...prev[currentTabKey]?.alignedData[columnKey],
            filtering: "",
          },
        },
      },
    }));

    clearFilters();
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <div>
        <strong style={{ width: "50%", marginBottom: 8 }}>Pick a range</strong>
      </div>
      <div className="d-flex gap-2 justify-content-between">
        <DatePicker
          value={localStartDate}
          onChange={(date) => setLocalStartDate(date)}
          format="MM-DD-YYYY"
          style={{ width: "50%", marginBottom: 8 }}
          placeholder="Start Date"
        />
        <DatePicker
          value={localEndDate}
          onChange={(date) => setLocalEndDate(date)}
          format="MM-DD-YYYY"
          style={{ width: "50%", marginBottom: 8 }}
          placeholder="End Date"
        />
      </div>
      <div className="d-flex justify-content-between flex-row-reverse">
        <Button
          type="primary"
          onClick={handleConfirm}
          size="small"
          disabled={!localStartDate || !localEndDate}
        >
          OK
        </Button>
        <Button onClick={handleReset} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default DateRangeFilter;
